import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { Reply } from '@mui/icons-material';
import moment from 'moment';
import { Button, Card, CardBody, Col, ModalFooter, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { msBaasAccount } from '../../../../store/user/actions';
import GenericModal from '../../../../components/Modais/GenericModal';
import ButtonGroupConta from '../ButtonGroupConta';
import formatMoney from '../../../../utils/formatMoneyBRL';
import { ReloadButtonStyle } from '../DigitalAccountBalaceContainer/style';
import { FiRefreshCw } from 'react-icons/fi';
import Loading from '../../../../components/Loader/Loading';

export default function AccordionInfoBankAccount({
  informacoesPessoais,
  user,
  setModalSenhaUnica,
  modalSenhaUnica,
  infoPessoaisRedux,
  setOperation,
  accountVisible,
  setAccountVisible,
  searchWithFilter,
}) {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const { loading_coban } = useSelector((state) => state.coban);
  const { loading } = useSelector((state) => state.user);

  const {
    user: { CONTA_BACEN }, saldo
  } = useSelector((state) => state.user);

  const [modalBaas, setModalBaas] = useState({
    open: false,
    header: '',
    body: '',
    footer: '',
  });

  function callbackMsBass(callback) {
    setModalBaas({ open: false, header: '', body: '', footer: '' });
    setModalBaas({
      open: true,
      header: <h1>{callback.retorno}</h1>,
      body: <span>{callback.mensagem}</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color={callback.retorno == 'erro' ? 'danger' : 'success'}
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }

  function handleModalBaasAccount() {
    setModalBaas({
      open: true,
      header: <h1>Disparo de conta</h1>,
      body: <span>Deseja enviar para o microserviço terceiros?</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color="secondary"
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Cancelar
            </Button>
            <Button
              color="success"
              size="sm"
              onClick={() => dispatch(msBaasAccount({CONTA: accountVisible.CONTA}, callbackMsBass))}
              block
            >
              Confirmar
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }
  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
              Conta
            </Typography>
            <Box sx={{ mr: 5 }}>
              <ButtonGroupConta
                user={user}
                setModalSenhaUnica={setModalSenhaUnica}
                modalSenhaUnica={modalSenhaUnica}
                infoPessoaisRedux={infoPessoaisRedux}
                setOperation={setOperation}
                alteraCliente={informacoesPessoais.alteraCliente}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!loading_coban ? (
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <Row>
                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Conta: </b>{' '}
                          </Typography>
                          {accountVisible && (
                            <>
                              <Select
                                defaultValue={accountVisible.UUID}
                                size='small'
                                onChange={({ target: { value } }) => {
                                  const account = CONTA_BACEN.filter((account) => account.UUID === value);
                                  setAccountVisible(account[0]);
                                }}
                              >
                                {CONTA_BACEN &&
                                  CONTA_BACEN.length > 0 &&
                                  CONTA_BACEN.map((account) => (
                                    <MenuItem
                                      key={account.UUID}
                                      value={account.UUID}
                                    >{`${account.CONTA}-${account.DIGITO}`}</MenuItem>
                                  ))}
                              </Select>
                              {informacoesPessoais.id_tipo_usuario !== 1 &&
                                accountVisible &&
                                accountVisible.CONTA &&
                                !accountVisible.DT_ENCERRAMENTO && (
                                  <Reply style={{ cursor: 'pointer', marginLeft: '0.5rem' }} onClick={handleModalBaasAccount} />
                                )}
                            </>
                          )}
                        </Box>
                      </Col>
                      <Col xs={5}></Col>

                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Data de Abertura:</b>
                          </Typography>
                          <Typography>
                            {accountVisible && accountVisible.DT_INSERT
                              ? moment(accountVisible.DT_INSERT).format('DD/MM/YYYY HH:mm:ss')
                              : '-----'}
                          </Typography>
                        </Box>
                      </Col>
                      
                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Saldo:</b>
                          </Typography>
                          {loading ? (
                            <Skeleton animation="wave" sx={{ width: '6rem', fontSize: '1rem' }} />
                          ) : (
                            <Typography>
                              {accountVisible && saldo
                                ? `R$${formatMoney(saldo.SALDO_ATUAL)}`
                                : '-----'}
                            </Typography>
                          )}
                        </Box>
                      </Col>

                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Data de Encerramento:</b>
                          </Typography>
                          <Typography>
                            {accountVisible && accountVisible.DT_ENCERRAMENTO
                              ? moment(accountVisible.DT_ENCERRAMENTO).format('DD/MM/YYYY HH:mm:ss')
                              : '-----'}
                          </Typography>
                        </Box>
                      </Col>

                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Saldo Bloqueado:</b>
                          </Typography>
                          {loading ? (
                            <Skeleton animation="wave" sx={{ width: '6rem', fontSize: '1rem' }} />
                          ) : (
                            <Typography>
                              {accountVisible && saldo
                                ? `R$${formatMoney(saldo.SALDO_BLOQUEADO)}`
                                : '-----'}
                            </Typography>
                          )}
                        </Box>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <ReloadButtonStyle onClick={() => searchWithFilter()}>
                      <FiRefreshCw />
                    </ReloadButtonStyle>
                  </div>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Loading color="blue" />
          )}
        </AccordionDetails>
      </Accordion>

      <GenericModal
        open={modalBaas.open}
        close={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
        header={modalBaas.header}
        body={modalBaas.body}
        footer={modalBaas.footer}
      />
    </>
  );
}

const styles = {
  textForm: {
    fontSize: '0.9em',
    color: '#8898aa',
    marginRight: '10px',
    marginBottom: '0',
  },
};
