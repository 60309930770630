import { Pix as PixIcon, Key, PriceChange, Equalizer, AccountBalance } from '@mui/icons-material/';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import CustomContainer from '../../components/Custom/CustomContainer';

import Chaves from './Chaves/Chaves';
import TransacoesPix from './TransacoesPix/TransacoesPix';
import Reivindicacoes from './Reivindicacoes/Reivindicacoes';
import Limits from './Limits/Limits';
import Dashboard from './Dashboard/Dashboard';

export default function Pix() {
  const components = [<Dashboard />, <Limits />, <Chaves />, <TransacoesPix />, <Reivindicacoes />];

  const [tabNumber, setTabNumber] = useState(0);

  return (
    <Box>
      <CustomContainer style={{ top: 20 }}>
        <Tabs sx={{ marginTop: -12 }} value={tabNumber} onChange={(event, number) => setTabNumber(number)}>
          <Tab
            icon={<Equalizer />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Dashboard"
          />
          <Tab
            icon={<PriceChange />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Limites"
          />
          <Tab
            icon={<Key />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Chaves"
          />
          <Tab
            icon={<PixIcon />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Transações"
          />
          <Tab
            icon={<AccountBalance />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Reivindicações"
          />
        </Tabs>
        {components[tabNumber]}
      </CustomContainer>
    </Box>
  );
}
