import React from 'react';
import { connect } from 'react-redux';
import { Input, Label, Button, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownIte, Form } from 'reactstrap';
import {
  getBalanceHistory,
  getTransactionsType,
  getTotalReport,
  getReportsExcel,
} from '../../store/transactions/actions.js';
import { getBasicUsers, getUserPdv } from '../../store/user/actions.js';
import { addSTATE } from '../../store/globals.js';
import moment from 'moment';
import { removeSpecialCharacters } from '../../utils/functions.js';
import { mesagePageResults } from '../../utils/constants.js';
import { dataInicio } from '../../utils/constants';
import AlertaCustomizado from '../Alertas/AlertaCustomizado';
import formatTextCamelCase from '../../utils/formatTextCamelCase';

import styled from 'styled-components';
import { SearchInput, DefaultInput, DefaultLabel, MaskInput, MaskInputMoney } from './styles/FilterReport.styles.js';
import GenericButton from '../Buttons/Button.js';
import Icon from '../Icons/IconsButton.js';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.05vw;
`;

class FilterReport extends React.Component {
  state = {
    limit: 10,
    CPFCNPJ_CLIENT: '',
    page: 1,
    dropdownOpen: false,
    query: '',
    queryPdv: '',
    userPdv: 'Selecione...',
    dataFinal: moment().format('YYYY-MM-DD'),
    valorMin: '',
    openAlerta: false,
  };

  componentDidMount() {
    this.props.getUserPdv();
    this.props.getBasicUsers();
    this.props.getTransactionsType();
    this.props.getTotalReport({
      limit: this.state.limit,
      page: this.state.page,
    });
  }

  handleInputChange = (e) => {
    this.setState(
      {
        query: e.target.value,
        userPdv: e.target.value,
      },
      () => {
        this.props.user.userPdv = this.props.user.userPdv.filter(
          (el) => el.NOME.toLowerCase().indexOf(this.state.query.toLowerCase()) > -1,
        );

        if (this.props.user.userTotal.length == 0 || this.state.query.length == 0) {
          this.props.getUserPdv();
        }
      },
    );
  };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.dropdownOpen });
  };

  currencyFormatter(value) {
    if (!Number(value)) return '';
    const amount = new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value / 100);

    return `${amount}`;
  }

  changePaymentValue = (value, name) => {
    this.setState({
      [name]: value.replaceAll('.', '').replace(',', '.').replace('R$\u00a0', ''),
    });
  };

  closeDropdown = () => {
    this.setState({ dropdownOpen: false });
  };

  filtrar = async () => {
    const valorMin = parseFloat(this.state.valorMin);
    const valorMax = parseFloat(this.state.valorMax);
    if (valorMin > valorMax) {
      this.setState({ openAlerta: true });
      return;
    }

    this.props.addSTATE('TRANSACTION_RESPONSE', '', {
      filtroReport: this.state,
    });
    if (this.state.tipoTransacao == 'Todas') {
      delete this.state.tipoTransacao;
    }
    if (this.state.tipoUsuario == 'Todos') {
      delete this.state.tipoUsuario;
    }
    if (this.state.modoPagamento == 'Todas') {
      delete this.state.modoPagamento;
    }
    this.props.reset();
    this.props.getTotalReport({
      ...this.state,
      page: 1,
      limit: parseInt(this.state.limit),
    });
  };

  onChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  render() {
    const { userPdv } = this.props.user;
    const { tiposTransacoes, modoPagamento, parceiro } = this.props.transactions;

    return (
      <Form className="form" onSubmit={(e) => e.preventDefault()}>
        <FormGroup className="mt-3">
          <AlertaCustomizado
            isOpen={this.state.openAlerta}
            toggle={() => this.setState({ openAlerta: false })}
            mensagem="O valor mínimo deve ser menor do que o valor máximo!"
          ></AlertaCustomizado>
          <>
            <RowContainer>
              <ColContainer>
                <DefaultLabel>Pesquisar</DefaultLabel>
                <SearchInput
                  onChange={(event) => this.onChange(event)}
                  autoFocus
                  type="text"
                  name="CPFCNPJ_CLIENT"
                  placeholder="Nome ou CPF/CNPJ"
                />
              </ColContainer>
              <ColContainer>
                <DefaultLabel>{dataInicio}</DefaultLabel>
                <DefaultInput
                  name="DATAINICIAL"
                  type="date"
                  max="9999-12-31"
                  size="sm"
                  onChange={(event) => this.onChange(event)}
                />
              </ColContainer>
              <ColContainer>
                <DefaultLabel>Data Final</DefaultLabel>
                <DefaultInput
                  name="DATAFINAL"
                  type="date"
                  max="9999-12-31"
                  size="sm"
                  onChange={(event) => this.onChange(event)}
                />
              </ColContainer>

              <ColContainer>
                <DefaultLabel>Tipo transação</DefaultLabel>
                <Input
                  onChange={(event) => this.onChange(event)}
                  type="select"
                  name="tipoTransacao"
                  size="sm"
                  style={{ width: '12vw' }}
                >
                  <option>Todas</option>
                  {tiposTransacoes &&
                    tiposTransacoes.map((transaction) => (
                      <option value={transaction.CODTIPO} onClick={this.onChange}>
                        {formatTextCamelCase(transaction.NOMECLATURA)}
                      </option>
                    ))}
                </Input>
              </ColContainer>
              <ColContainer>
                <DefaultLabel style={{ width: '10vw' }}>Tipo modo de pagamento</DefaultLabel>
                <Input
                  onChange={(event) => this.onChange(event)}
                  type="select"
                  name="modoPagamento"
                  size="sm"
                  style={{ width: '12vw' }}
                >
                  <option>Todas</option>
                  {modoPagamento &&
                    modoPagamento.map((transaction) => (
                      <option value={transaction.CODTIPO} onClick={this.onChange}>
                        {formatTextCamelCase(transaction.NOMECLATURA)}
                      </option>
                    ))}
                </Input>
              </ColContainer>
              <ColContainer>
                <DefaultLabel>Valor mínimo (R$)</DefaultLabel>{' '}
                <MaskInputMoney
                  decimalSeparator=","
                  thousandsSeparator="."
                  fixedDecimalScale={true}
                  name="valorMin"
                  value={this.state.valorMin}
                  onValueChange={(event) => {
                    const { formattedValue } = event;
                    this.changePaymentValue(formattedValue, 'valorMin');
                  }}
                  format={this.currencyFormatter}
                />
              </ColContainer>
              <ColContainer>
                <DefaultLabel size="sm">Valor máximo (R$)</DefaultLabel>
                <MaskInputMoney
                  decimalSeparator=","
                  thousandsSeparator="."
                  fixedDecimalScale={true}
                  value={this.state.valorMax}
                  name="valorMax"
                  onValueChange={(event) => {
                    const { formattedValue } = event;
                    this.changePaymentValue(formattedValue, 'valorMax');
                  }}
                  format={this.currencyFormatter}
                />
              </ColContainer>
              <ColContainer>
                <DefaultLabel>Parceiro</DefaultLabel>
                <Input
                  onChange={(event) => this.onChange(event)}
                  type="select"
                  name="tipoUsuario"
                  size="sm"
                  style={{ width: '12vw' }}
                >
                  <option>Todos</option>
                  {parceiro &&
                    parceiro.map((transaction) => (
                      <option value={transaction.ID} onClick={this.onChange}>
                        {transaction.DESCRICAO}
                      </option>
                    ))}
                </Input>
              </ColContainer>

              <ColContainer>
                <DefaultLabel>Status</DefaultLabel>
                <Input
                  onChange={(event) => this.onChange(event)}
                  type="select"
                  name="STATUS"
                  size="sm"
                  style={{ width: '12vw' }}
                >
                  <option value="">Todas</option>
                  <option value={1}>Aguardando</option>
                  <option value={2}>Aprovado</option>
                  <option value={3}>Cancelado</option>
                  <option value={4}>Vencido</option>
                </Input>
              </ColContainer>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <GenericButton
                  onClick={this.filtrar}
                  marginTop={'25px !important'}
                  width={'140px'}
                  heigth={'35px'}
                  type="submit"
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
                <GenericButton
                  onClick={() => {
                    this.props.getReportsExcel(this.state);
                  }}
                  marginTop={'25px !important'}
                  backgroundColor={'#ffffff'}
                  borderColor={'#2405F2'}
                  width={'140px'}
                  heigth={'35px'}
                  marginLeft={'10px'}
                  color={'#2405F2'}
                >
                  <Icon className="fas fa-file-export"></Icon>Exportar
                </GenericButton>
              </div>
            </RowContainer>
            <div style={{ display: 'flex' }}>
              <Label size="sm">{mesagePageResults}</Label>
              <Input
                onChange={(event) => this.onChange(event)}
                type="select"
                name="limit"
                id="limit"
                style={{ maxWidth: 80, marginLeft: 10 }}
                size="sm"
              >
                <option>10</option>
                <option>100</option>
                <option>250</option>
                <option>500</option>
                <option>1000</option>
                <option>5000</option>
              </Input>
            </div>
          </>
        </FormGroup>
      </Form>
    );
  }
}

const styles = {
  form: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
};

const mapStateToProps = ({ auth, transactions, user }) => {
  return { auth, transactions, user };
};

export default connect(mapStateToProps, {
  getBalanceHistory,
  getBasicUsers,
  getUserPdv,
  getReportsExcel,
  getTransactionsType,
  getTotalReport,
  addSTATE,
})(FilterReport);
