import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ReloadButtonStyle } from '../../User/components/DigitalAccountBalaceContainer/style';
import { FiRefreshCw } from "react-icons/fi";
import { Line } from "react-chartjs-2";
import api from "../../../service/api.js";
import valueEmpty from "../../../utils/valueEmpty.js";
import StatusContainerValue from "../../../components/StatusContainer/StatusContainerValue.js";
import Loading from "../../../components/Loader/Loading.jsx";
import StatusContainer from "../../../components/StatusContainer/StatusContainer.js";
import { formatDateBR } from "../../../utils/dateKyc.js";
import isCpf from "../../../utils/isCpf.js";
import formatCPF from "../../../utils/formatCPF.js";
import formatCNPJ from "../../../utils/formatCNPJ.js";
import CustomDataTable from "../../../components/DataTable/DataTable.jsx";
import { getPixSalesByInterval } from "../../../store/accounting/action.js";


export default function Dashboard() {
    const dispatch = useDispatch();
    const dadosDefaultChart = [0, 0, 0, 0, 0, 0, 0];

    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState(false);
    const [pagination, setPagination] = useState(false);

    const {
        loading_transaction,
    } = useSelector((state) => state.transactions);

    const {
        saques: { transacaoDebito, transacaoCredito, loading_accounting },
    } = useSelector((state) => state.accounting);

    useEffect(() => {
        handleReload();
    }, []);

    function handleReload() {
        getTransactions(1);
        dispatch(getPixSalesByInterval(30));
    }

    function getTransactions(page) {
        setLoading(true);
        const params = new URLSearchParams({
            page,
            limit: 10,
            data_inicial: moment().format('YYYY-MM-DD'),
            data_final: moment().format('YYYY-MM-DD'),
        });
        api
            .get(`/transacoes-pix?${params.toString()}`)
            .then(({ data }) => {
                if (data.retorno === 'sucesso') {
                    setTransactions(data.data);
                    setPagination(data.pagination);
                }
            })
            .finally(() => setLoading(false));
    }

    const formatDatas = (datas) => {
        var dataFormatada = [];
        dataFormatada = datas.map((num) => {
            var value = [];
            value = moment(num).isValid() ? moment(num).format('DD/MM/YYYY') : num;
            return value;
        });

        return dataFormatada;
    };

    return (
        <Box mt={1}>
            <Paper elevation={3} sx={{ marginTop: '1rem', padding: '1rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'end'} >
                            <ReloadButtonStyle onClick={() => handleReload()}>
                                <FiRefreshCw />
                            </ReloadButtonStyle>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                            <Typography fontSize={14}>Movimentações do dia</Typography>
                            <Typography fontSize={14}>Quantidade: {pagination && pagination.totalResult}</Typography>
                        </Box>
                        {
                            loading || loading_transaction ? (
                                <Loading color="blue" />
                            ) : (
                                <CustomDataTable
                                    loading={loading}
                                    pagination={pagination}
                                    previousPage={{ onClick: () => getTransactions(pagination.prevPage) }}
                                    nowPage={{ page: pagination.page, onClick: () => getTransactions(pagination.page) }}
                                    nextPage={{ onClick: () => getTransactions(pagination.nextPage) }}
                                    data={transactions}
                                    headers={[
                                        {
                                            name: 'DATA',
                                            selector: (row) => formatDateBR(row.DATA),
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'CPF/CNPJ',
                                            selector: (row) => row.CPFCNPJ && isCpf(row.CPFCNPJ) ? formatCPF(row.CPFCNPJ) : formatCNPJ(row.CPFCNPJ),
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'CONTA PAGADOR',
                                            selector: (row) => row.CONTA,
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'CONTA RECEBEDOR',
                                            selector: (row) => row.CONTA_DA_TRANSFERENCIA ? row.CONTA_DA_TRANSFERENCIA : '----',
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'VALOR',
                                            selector: (row) => valueEmpty(row.VALOR ? <StatusContainerValue code={row.TIPO_TRANSACAO} description={row.VALOR} /> : null),
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'PARCEIRO',
                                            selector: (row) => row.PARCEIRO ? (
                                                <StatusContainer code={row.PARCEIRO.toUpperCase()} description={row.PARCEIRO}></StatusContainer>
                                            ) : '-----',
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'CANAL',
                                            selector: (row) => row.CANAL,
                                            style: { textAlign: 'center' },
                                        }
                                    ]}
                                />
                            )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                            <Typography fontSize={14}>Transações</Typography>
                            <Typography fontSize={14}>(Últimos 15 dias)</Typography>
                        </Box>
                        {
                            loading || loading_accounting ? (
                                <Loading color="blue" />
                            ) : (
                                <Line
                                    data={{
                                        labels: transacaoDebito.length > 0 ? formatDatas(transacaoDebito.map((transacao) => transacao.DATA)) : dadosDefaultChart,
                                        datasets: [
                                            {
                                                label: 'Crédito',
                                                data: transacaoCredito.length > 0 ? transacaoCredito.map((transacao) => transacao.VALOR) : dadosDefaultChart,
                                                backgroundColor: 'rgba(12, 180, 42, 0.2)',
                                                borderColor: '#0CB42A',
                                                fill: true
                                            },
                                            {
                                                label: 'Débito',
                                                data: transacaoDebito.length > 0 ? transacaoDebito.map((transacao) => transacao.VALOR) : dadosDefaultChart,
                                                backgroundColor: 'rgba(234, 104, 82, 0.2)',
                                                borderColor: '#EA6852',
                                                fill: true
                                            },
                                        ],
                                    }}
                                    options={{
                                        aspectRatio: 1,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'bottom',

                                                labels: {
                                                    usePointStyle: true,
                                                },
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    label: function (context) {
                                                        var currentDataValue = context.raw;
                                                        return (+currentDataValue).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                />
                            )}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}