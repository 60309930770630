import React, { useEffect } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Loading from '../../components/Loader/Loading';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import CustomTable from '../../components/Table/Table';
import api from '../../service/api';
import { formatDateBR, unformatDateKyc } from '../../utils/dateKyc';
import formatCNPJ from '../../utils/formatCNPJ';
import formatCPF from '../../utils/formatCPF';
import {
  ButtonContainer,
  SaldoColor,
  SaldoContainer,
  SaldoDisponivelColor,
  SaldoStyle,
  SaldoStyleEnd,
  SaldoUtilizadoColor,
} from './style';

import { Alert, Box } from '@mui/material';
import { useState } from 'react';
import ResponsiveDialog from '../../components/Modais/Confirmar/ModalConfirmar';
import ModalReprovaPedido from '../../components/Modais/Confirmar/ModalReprovaPedido';
import { ContainerStatus } from '../../components/StatusContainer/StatusContainer.styles';
import formatMoney from '../../utils/formatMoneyBRL';
import { fontSize } from '@mui/system';
import BadgeTipoConta from '../../components/BadgeTipoConta/BadgeTipoConta';
import TableRechargeCard from '../../components/Tables/TableRechargeCard/tableRechargeCard';
import TableRechargePixKey from '../../components/Tables/TableRechargePixKey/TableRechargePixKey';
import TableRechargePixAccount from '../../components/Tables/TableRechargePixAccount/tableRechargePixAccount';

const styles = {
  Card: { border: '1px solid #f0f0f0', borderRadius: 10 },
};

export default function ModalPedidos({
  modal,
  toggle,
  approveOrder,
  orderId,
  reproveOrder,
  observation,
  setObservation,
  tipoConta,
}) {
  const [balance, setBalance] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalConfirmReprove, setModalConfirmReprove] = useState(false);
  const [order, setOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [loadingBalance, setLoadingBalance] = useState(false);
  useEffect(() => {
    if (order) {
      setLoadingBalance(true);
      api
        .get(`conveniada/saldo-geral?CPFCNPJ_CLIENTE=${order.CPFCNPJ_EMPRESA}`)
        .then(({ data }) => setBalance(data))
        .then(() => setLoadingBalance(false));
    }
  }, [order]);

  function checkBalance() {
    if (!order) {
      return true;
    }
    if (!balance) {
      return true;
    }
    if (balance.SALDO_ATUAL < order.VALOR_TOTAL) {
      return true;
    }

    return false;
  }

  function hideButtons() {
    if (order.FK_STATUS_TRANSACAO == '2') {
      return true;
    }

    if (order.FK_STATUS_TRANSACAO == '3') {
      return true;
    }

    return false;
  }

  function getOrder() {
    setLoading(true);
    api
      .get(
        `/conveniada/pedido?UUID=${orderId.UUID}
        `,
      )
      .then(({ data }) => {
        if (data) {
          setOrder(data.data);
        }
      })
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, [orderId]);

  function openModalConfirmAprove() {
    setTitle('Confirmar aprovação');
    setModalConfirm(true);
  }

  function openModalConfirmReprove() {
    setTitle('Confirmar reprovação');
    setModalConfirmReprove(true);
  }

  function closeModalConfirm() {
    setModalConfirm(false);
    setModalConfirmReprove(false);
    setTitle('');
  }

  function callBackApproveOrder(uuid) {
    approveOrder(uuid);
    closeModalConfirm();
  }

  function callBackReproveOrder(uuid) {
    reproveOrder(uuid, observation);
    closeModalConfirm();
  }

  return (
    <>
      <Modal style={{ maxWidth: 800 }} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3>Detalhes do pedido</h3>
          {order && <BadgeTipoConta tipoConta={order.FK_SWAP_CARTAO_TIPO_CONTA} styles={{ fontSize: '0.8rem' }} />}
        </ModalHeader>
        {!loading && !loadingBalance ? (
          <>
            {order && (
              <ModalBody>
                <Row xs="2">
                  <Col>
                    <h5>Número do pedido</h5>
                    <p>{order.UUID}</p>
                  </Col>
                  <Col>
                    <h5>CPF/CNPJ empresa</h5>
                    <p>
                      {order.CPFCNPJ_EMPRESA && order.CPFCNPJ_EMPRESA.length == 11
                        ? formatCPF(order.CPFCNPJ_EMPRESA)
                        : formatCNPJ(order.CPFCNPJ_EMPRESA)}
                    </p>
                  </Col>
                </Row>

                <Row xs="2">
                  <Col>
                    <h5>Empresa</h5>
                    <p>{order.NOME}</p>
                  </Col>
                  <Col>
                    <h5>Forma de pagamento</h5>
                    <p>{order.FORMA_PAGAMENTO}</p>
                  </Col>
                </Row>
                <Row xs="2">
                  <Col>
                    <h5>Data do pedido</h5>
                    <p>{formatDateBR(order.DATA_INSERT)}</p>
                  </Col>
                  <Col>
                    <h5>Status pedido</h5>
                    <p>
                      {order.FK_STATUS_TRANSACAO ? (
                        <StatusContainer code={order.FK_STATUS_TRANSACAO} description={order.STATUS} />
                      ) : null}
                    </p>
                  </Col>
                </Row>
                <Row xs="2">
                  <Col>
                    <h5>Data do agendamento</h5>
                    <p>{unformatDateKyc(order.DATA_AGENDAMENTO)}</p>
                  </Col>

                  {order.HASH_BOLETO && (
                    <Col>
                      <h5>Status Boleto</h5>
                      <p>
                        {order.STATUSTRANSACAO == '2' ? (
                          <ContainerStatus color="#00B423" background="rgba(0, 180, 35, 0.15)">
                            Pago
                          </ContainerStatus>
                        ) : (
                          <ContainerStatus color="#EAA800" background="rgba(234, 168, 0, 0.15)">
                            Aguardando pagamento
                          </ContainerStatus>
                        )}
                      </p>
                    </Col>
                  )}
                </Row>
                <Row xs="2">
                  {order.OBSERVACAO && (
                    <Col>
                      <h5>Observação</h5>
                      <p>{order.OBSERVACAO}</p>
                    </Col>
                  )}

                  <Col></Col>
                </Row>
                <Row xs="2">
                  {order.VALOR && (
                    <Col>
                      <h5>Valor do pedido</h5>
                      <p>R${formatMoney(order.VALOR)}</p>
                    </Col>
                  )}

                  {order.TAXA && (
                    <Col>
                      <h5>Valor da taxa</h5>
                      <p>R${formatMoney(order.TAXA)}</p>
                    </Col>
                  )}
                </Row>
                {order && order.TIPO_CONTA != 1 && balance && order.VALOR_TOTAL > balance.SALDO_ATUAL && (
                  <Alert severity="error">Saldo insuficiente</Alert>
                )}

                {order && order.TIPO_CONTA == 1 && order.VALOR_TOTAL > balance.SALDO_DISPONIVEL && (
                  <Alert severity="error">Saldo insuficiente</Alert>
                )}
                <div style={{ overflowY: 'auto' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                    {balance && (
                      <SaldoContainer>
                        <SaldoStyle>
                          Saldo: <SaldoColor> R${formatMoney(balance.SALDO_ATUAL)}</SaldoColor>
                        </SaldoStyle>
                        {order.TIPO_CONTA == 1 && (
                          <>
                            <SaldoStyle>
                              Saldo utilizado:
                              <SaldoUtilizadoColor> R${formatMoney(balance.SALDO_UTILIZADO)}</SaldoUtilizadoColor>
                            </SaldoStyle>

                            <SaldoStyle>
                              Saldo disponível:
                              <SaldoDisponivelColor color={balance.SALDO_DISPONIVEL != '0' ? 'positivo' : ''}>
                                R${formatMoney(balance.SALDO_DISPONIVEL)}
                              </SaldoDisponivelColor>
                            </SaldoStyle>
                          </>
                        )}
                      </SaldoContainer>
                    )}
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'end' }}>
                      <SaldoStyleEnd>
                        Valor total pedido <SaldoColor> R${formatMoney(order.VALOR_TOTAL)}</SaldoColor>
                      </SaldoStyleEnd>
                      {order.VALOR_SOMA && order.VALOR_SOMA != 0 && (
                        <SaldoStyleEnd>
                          Valor total pedido + agendamentos: <SaldoColor> R${formatMoney(order.VALOR_SOMA)}</SaldoColor>
                        </SaldoStyleEnd>
                      )}
                    </div>
                  </div>

                  <Box width={'100%'} p={'1.5rem 0'}>
                    {order.ITENS.length != 0 ? (
                      <>
                        <div
                          style={{
                            margin: '1.5rem 0 1rem 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <h4>Pedidos cartão</h4>
                          {order.VALOR_CARTAO && (
                            <SaldoStyleEnd>
                              Valor total cartão: <SaldoColor> R${formatMoney(order.VALOR_CARTAO)}</SaldoColor>
                            </SaldoStyleEnd>
                          )}
                        </div>

                        <TableRechargeCard data={order.ITENS} />
                      </>
                    ) : (
                      ''
                    )}
                    {order.ITENS_PIX.length != 0 && (
                      <>
                        <div
                          style={{
                            margin: '1.5rem 0 1rem 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <h4>Pedidos pix chave</h4>
                          {order.VALOR_PIX && (
                            <SaldoStyleEnd>
                              Valor total pix: <SaldoColor> R${formatMoney(order.VALOR_PIX)}</SaldoColor>
                            </SaldoStyleEnd>
                          )}
                        </div>
                        <TableRechargePixKey data={order.ITENS_PIX} />
                      </>
                    )}
                    {order.ITENS_CONTA.length != 0 && (
                      <>
                        <div
                          style={{
                            margin: '1.5rem 0 1rem 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <h4>Pedidos pix manual</h4>
                          {order.VALOR_CONTA && (
                            <SaldoStyleEnd>
                              Valor total pix manual: <SaldoColor> R${formatMoney(order.VALOR_CONTA)}</SaldoColor>
                            </SaldoStyleEnd>
                          )}
                        </div>

                        <TableRechargePixAccount data={order.ITENS_CONTA} />
                      </>
                    )}
                  </Box>

                  <ButtonContainer style={{ display: hideButtons() ? 'none' : '' }}>
                    <Button onClick={() => openModalConfirmAprove()} disabled={checkBalance()} color="success">
                      Aprovar
                    </Button>
                    <Button onClick={() => openModalConfirmReprove()} color="danger">
                      Reprovar
                    </Button>
                  </ButtonContainer>
                </div>
              </ModalBody>
            )}
          </>
        ) : (
          <Loading color="blue" />
        )}
        <ResponsiveDialog
          buttonTexts={[
            { name: 'Aprovar', color: '#2DCE89' },
            { name: 'Cancelar', color: '#F5365C' },
          ]}
          open={modalConfirm}
          callFunction={() => callBackApproveOrder(order.UUID)}
          title={title}
          close={() => closeModalConfirm()}
        />

        <ModalReprovaPedido
          buttonTexts={[
            { name: 'Reprovar', color: '#2DCE89' },
            { name: 'Cancelar', color: '#F5365C' },
          ]}
          open={modalConfirmReprove}
          observation={observation}
          setObservation={setObservation}
          callFunction={() => callBackReproveOrder(order.UUID)}
          title={title}
          close={() => closeModalConfirm()}
        />
      </Modal>
    </>
  );
}
