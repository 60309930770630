import React, { useEffect, useState } from "react";
import { Paper, Skeleton, Typography } from "@mui/material";
import InputDate from "../Filter/components/InputDate";
import { MdOutlineAccountBalance, MdOutlineAccountBalanceWallet, MdOutlineLock, MdOutlineLockClock } from "react-icons/md";

const CardBalance = ({
    title, date, setDate, balance = "----", type = "totalBalance",
    colors = ['red', 'green', 'blue'], loading = false
}) => {
    const [responsiveSize, setResponsiveSize] = useState(1);

    const [red, green, blue] = colors;
    const icon = {
        "totalBalance": <MdOutlineAccountBalanceWallet style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />,
        "blockedBalance": <MdOutlineLock style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />,
        "celcoinBalance": <MdOutlineAccountBalanceWallet style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />,
        "totalPendente": <MdOutlineLockClock style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />,
        "totalPagamentosDia": <MdOutlineAccountBalance style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />,
    };

    const updateStyle = () => {
        const vw = window.innerWidth * 0.0095;
        const vh = window.innerHeight * 0.0095;
        setResponsiveSize((vh + vw) * 1.3);
    }

    useEffect(() => {
        updateStyle();
        window.addEventListener("resize", updateStyle);
        return () => window.removeEventListener("resize", updateStyle);
    }, []);

    return (
        <Paper
            style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: "space-evenly",
                height: '8rem',
                padding: '1rem',
                borderLeft: `10px solid rgb(${red}, ${green}, ${blue})`,
                width: responsiveSize > 25 ? '33%' : responsiveSize > 16 ? '50%' : '100%',
                minWidth: '150px'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexFlow: `row ${responsiveSize < 20 ? 'wrap-reverse' : ''}`,
                    alignItems: 'start',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                <div
                    style={{ maxWidth: "300px" }}
                >
                    <Typography sx={{ fontWeight: '200', fontSize: `${0.4 * responsiveSize}px` }}>{title}</Typography>
                    {loading ? (
                        <Skeleton animation="wave" sx={{ width: '100%', fontSize: `${responsiveSize}px` }} />
                    ) : (
                        <Typography sx={{ fontWeight: '700', fontSize: `${responsiveSize}px` }}>{balance}</Typography>
                    )}
                </div>
                <div>
                    <div
                        style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.1)`,
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {icon[type]}
                    </div>
                </div>
            </div>
            {date && setDate && (
                <div
                    style={{
                        display: 'flex',
                        flexFlow: 'row',
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                >
                    <InputDate
                        label="Dia:"
                        name="data_filtro_saldo"
                        value={date}
                        setValue={(value) => setDate(value)}
                        style={{
                            div: {
                                display: 'flex',
                                flexFlow: 'row',
                                border: `1px rgba(${red}, ${green}, ${blue}, 0.1)`,
                                borderStyle: 'none none solid none'
                            },
                            input: {
                                fontWeight: 'bold',
                                border: 'none'
                            }
                        }}
                    />
                </div>
            )}
        </Paper>
    )
}

export default CardBalance;