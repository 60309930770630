import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, Row } from 'reactstrap';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';
import { CardFooter } from 'reactstrap';
import { ExpandMore, ReceiptLong } from '@mui/icons-material';

import { formatMoney } from '../../../../utils/formatMoneyBRL';
import Table from '../../../../components/Table/Table';
import NoRegisterFound from '../../../../components/Texts/NoRegisterFound.jsx';
import StatusContainerValue from '../../../../components/StatusContainer/StatusContainerValue.js';
import valueEmpty from '../../../../utils/valueEmpty.js';
import InputDate from '../../../../components/Filter/components/InputDate';
import * as Styled from '../../../../components/Filter/styles/Filter.style';
import Button from '../../../../components/Button/Button.style';
import Icon from '../../../../components/Icon/Icon.styles';
import Loading from '../../../../components/Loader/Loading';
import GenericButton from '../../../../components/Buttons/Button';
import ModalBoletoDetails from '../modals/components/ModalBoletoDetails/ModalBoletoDetails';

export default function ExtractAccountCard({ accountVisible, filter, setFilter, searchWithFilter, user }) {
  const [expanded, setExpanded] = useState(true);
  const { saldo, loading } = useSelector((state) => state.user);
  const [modalBoletoDetails, setModalBoletoDetails] = useState(false);
  const [boletoId, setBoletoId] = useState(false);
  const [senha, setSenha] = useState({
    SENHA: '',
    STATUS: false,
    ERRO: false,
  });

  
  const fichaGrafica = saldo && saldo.HISTORICO;
  
  const renderTable = () => (loading ? true : false);

  useEffect(() => {
    searchWithFilter();
  }, [accountVisible && accountVisible.CONTA]);

  const renderFilter = () => {
    return (
      <div style={{ padding: '30px 0px 2.5px 0px' }}>
        {/* retirado para aguardar o desenvilvimento de senhas
        {localStorage.getItem('nivelAcesso') != 1 && senha.STATUS === false && (
          <>
            <h4>Digite a senha de permissão para visualizar a ficha gráfica</h4>
            <Styled.InputsContainer className="Senha Acesso">
              <InputPassword
                label="Senha"
                name="SENHA"
                value={senha.SENHA}
                setValue={(value) => setSenha({ ...senha, SENHA: value })}
                erro={senha.ERRO}
                erroMenssagem={'Senha incorreta'}
              />
              <div>
                <Button
                  width={'110px'}
                  marginTop={'34px !important'}
                  marginLeft={'15px'}
                  onClick={() => clickButton()}
                >
                  Confirmar
                </Button>
              </div>
            </Styled.InputsContainer>
          </>
        )} */}

        {localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
          <Styled.InputsContainer>
            <div>
              <Skeleton animation={false} width={60} />
              <Skeleton animation={false} width={100} />
            </div>
            <div>
              <Skeleton animation={false} width={60} />
              <Skeleton animation={false} width={100} />
            </div>
            <Skeleton animation={false} width={100} />
          </Styled.InputsContainer>
        ) : localStorage.getItem('nivelAcesso') == 1 || senha.STATUS === true ? (
          <Styled.FilterContainer>
            <Styled.InputsContainer>
              <InputDate
                label="Data Inicial"
                name="DATA_INICIO"
                value={filter.DATA_INICIO}
                setValue={(value) => setFilter({ ...filter, DATA_INICIO: value })}
              />
              <InputDate
                label="Data Final"
                name="DATA_FIM"
                value={filter.DATA_FIM}
                setValue={(value) => setFilter({ ...filter, DATA_FIM: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <Button
                  width={'110px'}
                  marginTop={'34px !important'}
                  marginLeft={'15px'}
                  onClick={() => searchWithFilter()}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </Button>
              </div>
            </Styled.InputsContainer>
          </Styled.FilterContainer>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          onClick={() => setExpanded(!expanded)}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-content"
        >
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Extrato
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <div>
              <Card>
                <Row style={{ marginBottom: '20px' }}>
                  <div className="col" style={{ marginLeft: '25px', marginRight: '25px' }}>
                    {renderFilter()}
                    {renderTable() ? (
                      <Loading color="#2405f2" />
                    ) : (
                      <div>
                        {fichaGrafica && fichaGrafica.length > 0 ? (
                          localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
                            <>
                              <Skeleton animation={false} fullWidht height={60} />
                              <Styled.InputsContainer
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                              </Styled.InputsContainer>
                            </>
                          ) : localStorage.getItem('nivelAcesso') == 1 || senha.STATUS == true ? (
                            <Table
                              style={{
                                textAlign: 'center',
                                tableLayout: 'fixed',
                              }}
                              headers={[
                                {
                                  info: 'Data/Hora',
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: 'Tipo',
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: 'Descrição',
                                  style: { textAlign: 'left' },
                                },
                                {
                                  info: 'Valor',
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: 'Saldo Anterior',
                                  style: { textAlign: 'right' },
                                },
                                {
                                  info: 'Saldo',
                                  style: { textAlign: 'right' },
                                },
                                {
                                  info: 'Ações',
                                  style: { textAlign: 'center' },
                                },
                              ]}
                            ></Table>
                          ) : null
                        ) : localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
                          <Skeleton animation={false} fullWidht height={60} />
                        ) : (
                          <NoRegisterFound />
                        )}

                        <InfiniteScroll
                          dataLength={fichaGrafica && fichaGrafica.length}
                          hasMore={true}
                          height={user && user.saldo && user.saldo.HISTORICO.length}
                        >
                          <Table
                            style={{ textAlign: 'center', tableLayout: 'fixed' }}
                            data={
                              fichaGrafica
                                ? localStorage.getItem('nivelAcesso') == 1 || senha.STATUS == true
                                  ? fichaGrafica.map((log, index) => {
                                      return {
                                        data: [
                                          {
                                            info: log.DATAHORA,
                                            style: { textAlign: 'center' },
                                          },
                                          {
                                            info: log.TIPO ? log.TIPO : '-',
                                            style: { textAlign: 'center' },
                                          },
                                          {
                                            info: log.DESCRICAO,
                                            style: { textAlign: 'left' },
                                          },
                                          {
                                            info: valueEmpty(
                                              log.VALOR ? (
                                                <StatusContainerValue code={log.TIPO} description={log.VALOR} />
                                              ) : null,
                                            ),
                                            style: {
                                              textAlign: 'center',
                                              whiteSpace: 'nowrap',
                                            },
                                          },
                                          {
                                            info: log.SALDO_ANTERIOR ? `R$${formatMoney(log.SALDO_ANTERIOR)}` : '-',
                                            style: { textAlign: 'right' },
                                          },
                                          {
                                            info: log.SALDO_ATUAL ? `R$${formatMoney(log.SALDO_ATUAL)}` : '-',
                                            style: { textAlign: 'right' },
                                          },
                                          {
                                            info: log.ID_BOLETO ? (
                                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <GenericButton
                                                  width={'40px'}
                                                  backgroundColor="#f0a24a"
                                                  borderColor="#f0a24a"
                                                  size="m"
                                                  onClick={() => {
                                                    setBoletoId(log.ID_BOLETO);
                                                    setModalBoletoDetails(true);
                                                  }}
                                                >
                                                  <Tooltip title="Detalhes pagamento">
                                                    <ReceiptLong />
                                                  </Tooltip>
                                                </GenericButton>
                                              </div>
                                            ) : (
                                              '----'
                                            ),
                                            style: { textAlign: 'center' },
                                          },
                                        ],
                                      };
                                    })
                                  : null
                                : null
                            }
                          />
                        </InfiniteScroll>
                      </div>
                    )}
                  </div>
                </Row>
              </Card>
            </div>
            <Typography>
              <CardFooter></CardFooter>
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ModalBoletoDetails
        open={modalBoletoDetails}
        boletoId={boletoId}
        toggle={() => {
          setModalBoletoDetails(false);
          setBoletoId(false);
        }}
      />
    </>
  );
}
