import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import api from '../../../service/api';
import { unformatDateKyc } from '../../../utils/dateKyc';
import { modificarConveniada } from '../../../store/coban/actions';
import { useDispatch, useSelector } from 'react-redux';
import ModalMessageGeneric from '../Mensagem/ModalMessageGeneric';
import Loading from '../../Loader/Loading';
import Select from 'react-select';


export default function ModalAdicionarAoPortalRH({ open, handleClose, setReload, reload }) {
  const dispatch = useDispatch();

  const { listUsersConvenied } = useSelector((state) => state.dashboard);

  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [companiesNotOnRh, setCompaniesNotOnRh] = useState([]);
  const [stateHandleConvenied, setStateHandleConvenied] = useState(false);
  const { CONVENED_RESPONSE } = useSelector((state) => state.coban);
  const [UUID, setUUID] = useState('');
  const [companyToSave, setCompanyToSave] = useState({});
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);

  const getUserList = async () => {
      const res = await api.post('/usuario/listar/todos', {});
      setCompanies(res.data.USUARIOS);
      setLoadingCompanies(false);
  };

  useEffect(() => {
    if (open) {
      getUserList();
    } else {
      setCompanies([]);
      setLoadingCompanies(true);
    }
  }, [open]);

  useEffect(() => {
    if (!loadingCompanies) {
      const notOnRH = companies.filter(
        (item) =>
          item.CPFCNPJ.length > 11 &&
          item.UUID !== undefined &&
          !listUsersConvenied.find((rhItem) => rhItem.UUID === item.UUID),
      );
      setCompaniesNotOnRh(notOnRH);
    }
  }, [loadingCompanies, open]);

  const handleSelect = (UUID) => {
    setUUID(UUID.value);
  };

  const save = () => {
    if (UUID) {
      const seachCompanyToSave = companiesNotOnRh.find((item) => item.UUID === UUID);
      setCompanyToSave(seachCompanyToSave);
      setStateHandleConvenied(true);
    }
  };

  useEffect(() => {
    stateHandleConvenied && saveConvenied();
  }, [stateHandleConvenied]);

  function saveConvenied() {
    setLoading(true);
    setStateHandleConvenied(false);
    const dados = {
      ID: companyToSave.ID,
      NOME: companyToSave.NOME,
      CPFCNPJ_CLIENTE: companyToSave.CPFCNPJ,
      DATA: unformatDateKyc(companyToSave.NASCIMENTO),
      EMAIL: companyToSave.EMAIL,
      FONECEL: companyToSave.FONECEL,
      CONVENIADA: 'S',
      CEP: companyToSave.CEP,
      ENDERECO: companyToSave.ENDERECO,
      NUMERO: companyToSave.NUMERO,
      COMPLEMENTO: companyToSave.COMPLEMENTO,
      BAIRRO: companyToSave.BAIRRO,
      CIDADE: companyToSave.CIDADE,
      UF: companyToSave.UF,
      CONTA: companyToSave.CONTA,
      AGENCIA: companyToSave.AGENCIA,
      UUID: companyToSave.UUID,
    };

    dispatch(modificarConveniada(dados)).then(() => {
      setResponse(CONVENED_RESPONSE);
      setLoading(false);
      setModalSuccess(true);
      handleClose();
    });
  }

  function close() {
    setReload(!reload);
    setModalSuccess(false);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',

    boxShadow: 24,
  };

  return (
    <>
      {CONVENED_RESPONSE ? (
        <ModalMessageGeneric open={modalSuccess} toggle={() => close()} retorno={CONVENED_RESPONSE} />
      ) : (
        <ModalMessageGeneric open={modalSuccess} toggle={() => close()} retorno={response} />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} borderRadius={'6px '}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <Box
              width={'100%'}
              height={'4rem'}
              bgcolor={'#1976d2'}
              p={'1rem'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              borderRadius={'6px 6px 0 0'}
            >
              <span style={{ fontSize: '1rem', color: '#fafafa', lineHeight: '0.1' }}>
                Adicionar empresa ao portal RH
              </span>
              <Button fontSize={'2rem'} onClick={handleClose}>
                <IoIosClose fontSize={'1.5rem'} color="#ffffff" />
              </Button>
            </Box>
          </Box>
          {loading ? (
            <Loading color="blue" />
          ) : (
            <Box width={'100%'} p={'3rem 1rem 3rem 1rem'}>
              <Select
                options={companiesNotOnRh.map((user) => ({
                  label: user.NOME,
                  value: user.UUID,
                }))}
                onChange={handleSelect}
              />
            </Box>
          )}
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} p={'1rem'}>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="outlined" onClick={() => save()}>
              Adicionar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
