import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Badge, Button, ModalFooter } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { formatMoney } from '../../utils/formatMoneyBRL';
import formatCPF from '../../utils/formatCPF';
import formatCNPJ from '../../utils/formatCNPJ';
import moment from 'moment';
import { Reply } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { msBaasReceiverBoleto } from '../../store/user/actions';
import GenericModal from '../../components/Modais/GenericModal';
import { isCnpj } from '../../utils/isCpf';

const ModalInformationsBillet = (props) => {
  const dispatch = useDispatch();
  const { modal, toggle, title, data } = props;
  const {
    ID,
    ID_TRANSACAO,
    NOME,
    DATA,
    HORA,
    STATUS,
    ID_TIPO_USUARIO_BENEFICIARIO,
    VALOR,
    VENDA_DIGITADA,
    NOME_BENEFICIARIO,
    TAXA,
    CPFCNPJ_BENEFICIARIO,
    VENCIMENTO,
    CPFCNPJ,
    LINHADIGITAVEL,
    CODSTATUS,
    IDPAGAMENTO,
    DATA_LIQUIDACAO,
    DATA_PAGAMENTO_CLIENTE,
    DATA_COMPENSACAO_BANCO,
    DATA_BAIXADO,
  } = data;

  const [modalBaas, setModalBaas] = useState({
    open: false,
    header: '',
    body: '',
    footer: '',
  });

  function handleCallbackBassBoletoReceiver() {
    setModalBaas({
      open: true,
      header: <h1>Disparo de recebimento de boleto</h1>,
      body: <span>Deseja enviar para o microserviço terceiros?</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color="secondary"
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Cancelar
            </Button>
            <Button color="success" size="sm" onClick={() => dispatch(msBaasReceiverBoleto({ ID }, callbackMsBass))} block>
              Confirmar
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }

  function callbackMsBass(callback) {
    setModalBaas({ open: false, header: '', body: '', footer: '' });
    setModalBaas({
      open: true,
      header: <h1>{callback.retorno}</h1>,
      body: <span>{callback.mensagem}</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color={callback.retorno == 'erro' ? 'danger' : 'success'}
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Box sx={{ display: 'flex' }}>
            <h3>{title}</h3>
            {+ID_TIPO_USUARIO_BENEFICIARIO !== 1 && +CODSTATUS === 2 && (
              <Reply style={{ cursor: 'pointer' }} onClick={handleCallbackBassBoletoReceiver} />
            )}
          </Box>
        </ModalHeader>
        <ModalBody
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', wordBreak: 'break-word' }}
        >
          <Row style={styles.metade}>
            <Col>
              <h5>Código da Transação</h5>
              <p>{ID_TRANSACAO}</p>
            </Col>
          </Row>
          <Row style={styles.metade}>
            <Col>
              <h5>Status Transação</h5>
              {CODSTATUS == 1 && <Badge color="info">AGUARDANDO</Badge>}
              {CODSTATUS == 2 && <Badge color="success">APROVADO</Badge>}
              {CODSTATUS == 3 && <Badge color="danger">CANCELADO</Badge>}
              {CODSTATUS == 4 && <Badge color="secondary">VENCIDO</Badge>}
            </Col>
          </Row>

          <Row style={styles.metade}>
            <Col>
              <h5>Data/Hora</h5>
              <p>
                {moment(DATA).isValid() ? moment(DATA).format('DD/MM/YYYY') : DATA} {HORA}
              </p>
            </Col>
          </Row>
          {CODSTATUS == 2 && (
            <Row style={styles.metade}>
              <Col>
                <h5>Data/Hora Liquidação:</h5>
                <p>
                  {moment(DATA_LIQUIDACAO).isValid()
                    ? moment(DATA_LIQUIDACAO).format('DD/MM/YYYY HH:MM:SS')
                    : DATA_LIQUIDACAO}
                </p>
              </Col>
            </Row>
          )}

          <Row style={styles.metade}>
            <Col>
              <h5>Vencimento</h5>
              <p>{moment(VENCIMENTO).isValid() ? moment(VENCIMENTO).format('DD/MM/YYYY') : VENCIMENTO}</p>
            </Col>
          </Row>
          <Row style={styles.metade}>
            <Col>
              <h5>Valor</h5>
              <p>R$ {formatMoney(VALOR)}</p>
            </Col>
          </Row>
          <Row style={styles.metade}>
            <Col>
              <h5>Taxa</h5>
              <p>R$ {formatMoney(TAXA)}</p>
            </Col>
          </Row>
          <Row style={styles.metade}>
            <Col>
              <h5>Venda Digitada</h5>
              <p>R$ {formatMoney(VENDA_DIGITADA)}</p>
            </Col>
          </Row>
          <Row style={styles.metade}>
            <Col>
              <h5>Nome</h5>
              <p>{NOME}</p>
            </Col>
          </Row>
          <Row style={styles.metade}>
            <Col>
              <h5>CPF/CNPJ</h5>
              <p>{CPFCNPJ && isCnpj(CPFCNPJ) ? formatCNPJ(CPFCNPJ) : formatCPF(CPFCNPJ)}</p>
            </Col>
          </Row>
          <Row style={styles.metade}>
            <Col>
              <h5>Nome Beneficiário</h5>
              <p>{NOME_BENEFICIARIO}</p>
            </Col>
          </Row>
          <Row style={styles.metade}>
            <Col>
              <h5>CPF/CNPJ Beneficiário</h5>
              <p>
                {CPFCNPJ && CPFCNPJ_BENEFICIARIO.length > 11
                  ? formatCNPJ(CPFCNPJ_BENEFICIARIO)
                  : formatCPF(CPFCNPJ_BENEFICIARIO)}
              </p>
            </Col>
          </Row>
          {CODSTATUS == 2 && (
            <>
              <Row style={styles.metade}>
                <Col>
                  <h5>Data de pagamento</h5>
                  <p>
                    {moment(DATA_PAGAMENTO_CLIENTE).isValid()
                      ? moment(DATA_PAGAMENTO_CLIENTE).format('DD/MM/YYYY')
                      : DATA_PAGAMENTO_CLIENTE}
                  </p>
                </Col>
              </Row>
              <Row style={styles.metade}>
                <Col>
                  <h5>Data de compensação</h5>
                  <p>
                    {moment(DATA_COMPENSACAO_BANCO).isValid()
                      ? moment(DATA_COMPENSACAO_BANCO).format('DD/MM/YYYY')
                      : DATA_COMPENSACAO_BANCO}
                  </p>
                </Col>
              </Row>
            </>
          )}
          {CODSTATUS == 3 && (
            <>
              <Row style={styles.metade}>
                <Col>
                  <h5>Data baixado</h5>
                  <p>{moment(DATA_BAIXADO).isValid() ? moment(DATA_BAIXADO).format('DD/MM/YYYY') : DATA_BAIXADO}</p>
                </Col>
              </Row>
            </>
          )}
          <Row style={styles.cheio}>
            <Col>
              <h5>ID do Boleto</h5>
              <p>{IDPAGAMENTO}</p>
            </Col>
          </Row>

          <Row style={styles.cheio}>
            <Col>
              <h5>Código de barras</h5>
              <p>{LINHADIGITAVEL}</p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <GenericModal
        open={modalBaas.open}
        close={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
        header={modalBaas.header}
        body={modalBaas.body}
        footer={modalBaas.footer}
      />
    </>
  );
};

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

export default ModalInformationsBillet;
