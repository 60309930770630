import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, FormGroup, Input, InputGroup, Row, Col, Form, ModalFooter } from 'reactstrap';
import CartoesContaDigital from './CartoesContaDigital';
// import DropdownSelecct from './Dropdown';
import formatCPF from '../../../utils/formatCPF';
import formatCNPJ from '../../../utils/formatCNPJ';
import formatCEP from '../../../utils/formatCEP';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import formatDate from '../../../utils/formatDate';
import InputMask from 'react-input-mask';
import ModalSenha from '../../../components/Modais/Confirmar/ModalSenha';
import { VincularCartaoSwap as ModalVincularCartaoSwap, ModalTrocaSenha } from './modals/index';
import isCpf, { isCnpj } from '../../../utils/isCpf';
import { useDispatch, useSelector } from 'react-redux';
import { LogStatusUsuario, msBaasStatusAccount, statusUsuario } from '../../../store/user/actions';
import { Reply } from '@material-ui/icons';
import GenericModal from '../../../components/Modais/GenericModal';

const InfosUser = (props) => {
  const { status_usuario, motivo, user } = useSelector((state) => state.user);
  const [modalVincularCartaoSwap, setModalVincularCartaoSwap] = useState(false);
  const [idSwap, setIdSwap] = useState('');
  const [modalSenha, setModalSenha] = useState(false);
  const [trocaSenha, setTrocaSenha] = useState(false);
  const [modalBaas, setModalBaas] = useState({
    open: false,
    header: '',
    body: '',
    footer: '',
  });

  // const toggleDropdown = () => props.setState({ dropdownOpen: !props.state.dropdownOpen });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(statusUsuario());
    if (props.state.status_usuario == 9) {
      dispatch(LogStatusUsuario(props.state.cpf_cnpj));
    }
  }, []);

  function handleModalBaasStatusUsuario() {
    setModalBaas({
      open: true,
      header: <h1>Disparo de status</h1>,
      body: <span>Deseja enviar para o microserviço terceiros?</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color="secondary"
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Cancelar
            </Button>
            <Button
              color="success"
              size="sm"
              onClick={() => dispatch(msBaasStatusAccount({UUID: user.UUID}, callbackMsBass))}
              block
            >
              Confirmar
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }

  function callbackMsBass(callback) {
    setModalBaas({ open: false, header: '', body: '', footer: '' });
    setModalBaas({
      open: true,
      header: <h1>{callback.retorno}</h1>,
      body: <span>{callback.mensagem}</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color={callback.retorno == 'erro' ? 'danger' : 'success'}
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Row>
              <Col xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    {props.state.cpf_cnpj && isCpf(props.state.cpf_cnpj) ? <b>Nome: </b> : <b>Razão Social:</b>}
                  </p>
                  <Input
                    type="text"
                    name="Nome/Razão Social"
                    value={props.state.nome}
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>

              {isCpf(props.state.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Nome Mãe: </b>
                    </p>
                    <Input
                      type="text"
                      name="NOME_MAE"
                      value={props.state.nome_mae}
                      onChange={(e) => props.alterValueCallback(e)}
                      disabled={props.state.alteraCliente}
                      style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                    />
                  </InputGroup>
                </Col>
              )}

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    {props.state.cpf_cnpj && isCpf(props.state.cpf_cnpj) ? <b>CPF: </b> : <b>CNPJ:</b>}
                  </p>
                  <Input
                    type="text"
                    value={
                      props.state.cpf_cnpj && isCpf(props.state.cpf_cnpj)
                        ? formatCPF(props.state.cpf_cnpj)
                        : formatCNPJ(props.state.cpf_cnpj)
                    }
                    name="CPF/CNPJ"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={true}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle3}
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>{isCpf(props.state.cpf_cnpj) ? <b>Nascimento: </b> : <b>Fundação:</b>}</p>
                  <Input
                    type="text"
                    value={formatDate(props.state.nasc_fund)}
                    name="Nascimento/Fundação"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Email: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.email}
                    name="EMAIL"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Status Solicitação Cartão: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.StatusSolicitacaoCartao ? props.state.StatusSolicitacaoCartao : '-----'}
                    name="StatusSolicitacaoCartao"
                    disabled={true}
                    style={styles.inputStyle2}
                  />
                </InputGroup>
              </Col>

              {isCnpj(props.state.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Conveniada </b>
                    </p>
                    <select
                      type="select"
                      id="conveniada"
                      value={props.state.conveniada}
                      onChange={(e) => props.alterValueCallback(e)}
                      name="Conveniada"
                      style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                      disabled={props.state.alteraCliente}
                    >
                      <option value="N">Não</option>
                      <option value="S">Sim</option>
                    </select>
                  </InputGroup>
                </Col>
              )}

              {isCnpj(props.state.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Tipo Conta </b>
                    </p>
                    <select
                      type="select"
                      id="tipo_conta"
                      value={props.state.tipo_conta}
                      onChange={(e) => props.alterValueCallback(e)}
                      name="TIPOCONTA"
                      style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                      disabled={props.state.alteraCliente}
                    >
                      <option value="1">Conta Bancária</option>
                      <option value="2">Conta Cartão</option>
                    </select>
                  </InputGroup>
                </Col>
              )}

              {isCpf(props.state.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Matrícula: </b>
                    </p>
                    <Input
                      type="text"
                      value={props.state.matricula}
                      name="MATRICULA"
                      onChange={(e) => props.alterValueCallback(e)}
                      disabled={props.state.alteraCliente}
                      style={
                        props.state.alteraCliente
                          ? {
                              border: 'none',
                              marginTop: '-1%',
                              marginLeft: '5%',
                            }
                          : styles.inputStyle
                      }
                    />
                  </InputGroup>
                </Col>
              )}

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Telefone: </b>
                  </p>
                  <Input
                    type="text"
                    tag={InputMask}
                    mask={
                      props.state.telefone.length == '14' || props.state.telefone.length == '11'
                        ? '(99)99999-9999'
                        : '(99)9999-99999'
                    }
                    maskChar=""
                    value={props.state.alteraCliente ? formatPhoneNumber(props.state.telefone) : props.state.telefone}
                    name="TELEFONE"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Status Usuário: </b>
                  </p>
                  <select
                    type="select"
                    value={props.state.status_usuario}
                    name="STATUS_USUARIO"
                    id="status_usuario"
                    onChange={(e) => props.alterValueCallback(e)}
                    style={styles.inputStyle2}
                    disabled={true}
                  >
                    {!props.state.status_usuario && <option value=""></option>}
                    {status_usuario &&
                      status_usuario.length > 0 &&
                      status_usuario.map((status) => <option value={status.ID}>{status.DESCRICAO}</option>)}
                  </select>
                  {props.state.id_tipo_usuario !== 1 && props.state.status_usuario && (
                    <Reply style={{ cursor: 'pointer' }} onClick={handleModalBaasStatusUsuario} />
                  )}
                </InputGroup>
              </Col>

              {isCpf(props.user.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Empresa: </b>
                    </p>
                    <Input
                      type="text"
                      value={props.state.empresa}
                      name="EMPRESA"
                      onChange={(e) => props.alterValueCallback(e)}
                      disabled={props.state.alteraCliente}
                      style={
                        props.state.alteraCliente
                          ? {
                              border: 'none',
                              marginTop: '-1%',
                              marginLeft: '5%',
                            }
                          : styles.inputStyle
                      }
                    />
                  </InputGroup>
                </Col>
              )}
              {props.state.status_usuario == 9 && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Motivo: </b>
                    </p>
                    <Input
                      type="text"
                      value={motivo}
                      name="motivo"
                      onChange={(e) => props.alterValueCallback(e)}
                      disabled={true}
                      style={styles.inputStyle2}
                    />
                  </InputGroup>
                </Col>
              )}
            </Row>
            <hr />
            <Row>
              <Col xs={10}>
                <h3
                  style={{
                    marginBottom: '10px',
                    transform: `${!props.state.alteraCliente ? 'translateY(-10px)' : 'translateY(0)'} `,
                  }}
                >
                  {isCpf(props.user.cpf_cnpj) ? 'Endereço Cliente' : 'Endereço Empresa'}
                </h3>
              </Col>
              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>CEP: </b>
                  </p>
                  <Input
                    type="text"
                    value={formatCEP(props.state.cep)}
                    name="CEP"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Complemento: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.complemento}
                    name="COMPLEMENTO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={
                      props.state.alteraCliente
                        ? {
                            border: 'none',
                            marginTop: '-1%',
                            marginLeft: '5%',
                          }
                        : styles.inputStyle
                    }
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Endereço: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.endereco}
                    name="ENDERECO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Cidade: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.cidade}
                    name="CIDADE"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Bairro: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.bairro}
                    name="BAIRRO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Número: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.numero}
                    name="NUMERO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={
                      props.state.alteraCliente
                        ? {
                            border: 'none',
                            marginTop: '-1%',
                            marginLeft: '5%',
                          }
                        : styles.inputStyle
                    }
                  />
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>UF: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.uf}
                    name="UF"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  />
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </Form>

        {props.user.cartaoContaDigital != undefined && props.user.cartaoContaDigital.CPFCNPJ != undefined && (
          <CartoesContaDigital
            cartaoContaDigital={props.state.cartaoContaDigital}
            userContaDigitalCartao={props.user.cartaoContaDigital}
            openModalPasswordBlockDigitalCardCallback={(el) => props.openModalPasswordBlockDigitalCardCallback()}
            openModalSegundaViaCallback={(el) => props.openModalSegundaViaCallback()}
          />
        )}
      </CardBody>

      <ModalVincularCartaoSwap
        modal={modalVincularCartaoSwap}
        toggle={(e) => setModalVincularCartaoSwap(false)}
        idSwap={idSwap}
      />

      <ModalSenha
        modal={modalSenha}
        close={() => setModalSenha(false)}
        operation={'trocaSenha'}
        modalTrocaSenha={(e) => setTrocaSenha(true)}
      />

      <ModalTrocaSenha isOpen={trocaSenha} id={idSwap} toggle={(e) => setTrocaSenha(false)} />

      <GenericModal
        open={modalBaas.open}
        close={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
        header={modalBaas.header}
        body={modalBaas.body}
        footer={modalBaas.footer}
      />
    </Card>
  );
};

const styles = {
  formUserStyle: {
    fontSize: '0.9em',
    color: '#8898aa',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  textForm: {
    fontSize: '0.9em',
    color: '#8898aa',
    marginRight: '10px',
    marginBottom: '0',
  },
  inputStyle: {
    border: 'solid 1px #D3D3D3',
    marginTop: '2%',
    paddingLeft: '1%',
    width: '100%',
  },
  inputStyle2: {
    border: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
  },
  inputStyle3: {
    border: 'none',
    marginTop: '2%',
    display: 'block',
    width: '100%',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
  },
  Card: { border: '1px solid #f0f0f0', borderRadius: 10, marginBottom: 15 },
  foto: { height: '50%', width: '90%' },

  modalFooterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '1vw',
  },
};

export default InfosUser;
