import React from 'react';
import { connect } from 'react-redux';
import Table from '../../components/Table/Table.jsx';
import formatCPF from '../../utils/formatCPF';
import formatCNPJ from '../../utils/formatCNPJ';
import valueEmpty from '../../utils/valueEmpty.js';
import { centerItem } from '../../utils/constants.js';

// reactstrap components
import { Row } from 'reactstrap';
import {
  getWithdrawalTransactions,
  getWithdrawalTransactionsID,
  getTotalReport,
} from '../../store/transactions/actions.js';
import Loading from '../../components/Loader/Loading.jsx';
import { formatMoney } from '../../utils/formatMoneyBRL.js';
import FilterReport from '../../components/Filter/FilterReport';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import StatusContainer from '../../components/StatusContainer/StatusContainer.js';
import StatusContainerValue from '../../components/StatusContainer/StatusContainerValue.js';
class Report extends React.Component {
  state = {
    limit: 10,
    modal: false,
    saleInfos: {},
    page: 1,
    filtro: { page: 1 },
  };

  componentDidMount() {
    this.props.getTotalReport({
      limit: this.state.limit,
      page: this.state.page,
    });
  }

  resetPage = () => {
    this.setState({ filtro: { page: 1 } });
  };

  pagination = async (type = 'atual', pageClick) => {
    var { page } = this.state.filtro;
    var { pagination } = this.props.transactions;

    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await this.setState({ filtro: { page } });
    this.props.getTotalReport({
      ...this.props.transactions.filtroReport,
      page,
    });
  };

  renderTableSaque() {
    const { page } = this.state.filtro;
    const { pagination } = this.props.transactions;
    const DESCRICAOSTATUS = (res) => {
      let description = '';
      {
        res == 1 && (description = 'AGUARDANDO');
      }
      {
        res == 3 && (description = 'CANCELADO');
      }
      {
        res == 4 && (description = 'VENCIDO');
      }
      {
        res == 2 && (description = 'APROVADO');
      }
      {
        res == 5 && (description = 'EM ANÁLISE');
      }
      return description;
    };

    var { historico } = this.props.transactions;
    return (
      <>
        <Table
          id="table_relatorio"
          headers={[
            {
              info: 'Data/Hora',
              style: { textAlign: 'center' },
            },
            {
              info: 'NOME REMETENTE',
              style: { textAlign: 'left' },
            },
            {
              info: 'CPF/CNPJ REMETENTE',
              style: { textAlign: 'left' },
            },
            {
              info: 'NOME DESTINATÁRIO',
              style: { textAlign: 'left' },
            },
            {
              info: 'CPF/CNPJ DESTINATÁRIO',
              style: { textAlign: 'left' },
            },
            {
              info: 'Parceiro',
              style: { textAlign: 'left' },
            },
            {
              info: 'Tipo',
              style: { textAlign: 'left' },
            },
            {
              info: 'Status',
              style: { textAlign: 'center' },
            },
            {
              info: 'Valor Operação',
              style: { textAlign: 'center' },
            },
            {
              info: 'Taxa',
              style: { textAlign: 'center' },
            },
            {
              info: 'Valor pago',
              style: { textAlign: 'center' },
            },
          ]}
          data={
            historico &&
            historico.map((log) => {
              return {
                data: [
                  {
                    info: valueEmpty(log.DATAHORA),
                    style: { textAlign: 'center' },
                  },
                  {
                    info: valueEmpty(log.NOME_ORIGEM),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: valueEmpty(
                      log.DOCUMENTO_ORIGEM === null
                        ? '-----'
                        : log.DOCUMENTO_ORIGEM && log.DOCUMENTO_ORIGEM.length == 11
                        ? formatCPF(log.DOCUMENTO_ORIGEM)
                        : formatCNPJ(log.DOCUMENTO_ORIGEM),
                    ),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: valueEmpty(log.NOME_DESTINO),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: valueEmpty(
                      log.DOCUMENTO_DESTINO === null
                        ? '-----'
                        : log.DOCUMENTO_DESTINO && log.DOCUMENTO_DESTINO.length == 11
                        ? formatCPF(log.DOCUMENTO_DESTINO)
                        : formatCNPJ(log.DOCUMENTO_DESTINO),
                    ),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: log.TIPO_USUARIO ? (
                      <StatusContainer
                        code={log.TIPO_USUARIO.toUpperCase()}
                        description={log.TIPO_USUARIO}
                      ></StatusContainer>
                    ) : (
                      '-----'
                    ),
                    style: { textAlign: 'center' },
                  },
                  {
                    info: valueEmpty(log.DESCRICAO),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: valueEmpty(
                      log.STATUSTRANSACAO ? (
                        <StatusContainer
                          code={log.STATUSTRANSACAO}
                          description={DESCRICAOSTATUS(log.STATUSTRANSACAO)}
                        />
                      ) : null,
                    ),
                    style: { textAlign: 'center' },
                  },
                  {
                    info: valueEmpty(
                      log.VALOR_OPERACAO ? (
                        <StatusContainerValue code={log.TIPO} description={log.VALOR_OPERACAO} />
                      ) : null,
                    ),
                    style: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    info: valueEmpty(
                      log.SPREAD ? <StatusContainerValue code={log.TIPO} description={log.SPREAD} /> : null,
                    ),
                    style: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    info: valueEmpty(
                      log.VALOR_TRANSACAO ? (
                        <StatusContainerValue code={log.TIPO} description={log.VALOR_TRANSACAO} />
                      ) : null,
                    ),
                    style: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                ],
              };
            })
          }
          pagination={pagination}
          previousPage={{ onClick: () => this.pagination('prev') }}
          nowPage={{ page, onClick: this.pagination }}
          nextPage={{ onClick: () => this.pagination('next') }}
        />
      </>
    );
  }

  render() {
    const { loading } = this.props.user;
    return (
      <div>
        <CustomContainer>
          <Row>
            <div className="col">
              <FilterReport identificador={'saque'} reset={(el) => this.resetPage(el)}></FilterReport>
              {loading ? (
                <Loading color="rgb(201, 166, 62)" />
              ) : this.props.transactions.historico ? (
                this.renderTableSaque()
              ) : (
                <h3 style={centerItem}>Nenhum registro encontrado</h3>
              )}
            </div>
          </Row>
        </CustomContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, transactions, user }) => {
  return { auth, transactions, user };
};

export default connect(mapStateToProps, {
  getWithdrawalTransactions,
  getWithdrawalTransactionsID,
  getTotalReport,
})(Report);
