import React from 'react';
import Table from '../Table/Table';

import { formatDateBR } from '../../utils/dateKyc';
import Loading from '../Loader/Loading';

export default function ContratosConsentimentoTable({ contracts, loading, getDetailedContract }) {
  return (
    <>
      {!loading ? (
        <>
          {contracts && contracts.client && contracts.client.length > 0 ? (
            <Table
              size="sm"
              headers={[
                  {
                  info: 'Data aceite',
                  style: { textAlign: 'start' },
                },
                {
                  info: "Nome",
                  style: { textAlign: 'start' },

                },
                {
                  info: "CPF/CNPJ",
                  style: { textAlign: 'start' },

                },
                {
                  info: 'Código',
                  style: { textAlign: 'start' },
                },
                {
                  info: 'Ip',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'Parceiro',
                  style: { textAlign: 'center' },
                },
              ]}
              data={
                contracts &&
                contracts.client &&
                contracts.client.length > 0 &&
                contracts.client.map((contract, index) => {
                  return {
                    data: [
                      {
                        info: formatDateBR(contract.date_accept_contract),
                        style: { textAlign: 'start' },
                      },
                      {
                        info: contract.client_name,
                        style: { textAlign: 'start' },
                      },
                      {
                          info: contract.document,
                        style: { textAlign: 'start' },
                      },
                      {
                        info: contract.uuid,
                        style: { textAlign: 'start' },
                      },
                      {
                        info: contract.ip,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: contract.partner_name,
                        style: { textAlign: 'center' },
                      },
                    ],
                    onClick: () => getDetailedContract(contract.uuid),
                  };
                })
              }
            />
          ) : (
            <>
              <Table
                style={{ marginTop: '3rem' }}
                size="sm"
                headers={[
                    {
                  info: 'Data aceite',
                  style: { textAlign: 'start' },
                },
                {
                  info: "Nome",
                  style: { textAlign: 'start' },

                },
                {
                  info: "CPF/CNPJ",
                  style: { textAlign: 'start' },

                },
                {
                  info: 'Código',
                  style: { textAlign: 'start' },
                },
                {
                  info: 'Ip',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'Parceiro',
                  style: { textAlign: 'center' },
                },
                ]}
              />
              <h3 style={{ textAlign: 'center', marginTop: '1rem' }}>Nenhum registro encontrado</h3>
            </>
          )}
        </>
      ) : (
        <>
          <Loading color="blue" />
        </>
      )}
    </>
  );
}
