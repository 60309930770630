import moment from 'moment';
import React, { useEffect, useState } from 'react';
import GenericButton from '../../../components/Buttons/Button';
import api from '../../../service/api';
import { formatCNPJ, formatCPF, valueEmpty } from '../../../utils';
import { formatDateBR } from '../../../utils/dateKyc';
import { valueSort } from '../../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionsType } from '../../../store/transactions/actions';
import isCpf from '../../../utils/isCpf';
import StatusContainer from '../../../components/StatusContainer/StatusContainer';
import StatusContainerValue from '../../../components/StatusContainer/StatusContainerValue';
import CustomDataTable from '../../../components/DataTable/DataTable';
import ModalTransferenciasPix from '../../User/components/modals/components/ModalTransferenciasPix/ModalTransferenciasPix';
import { Autocomplete, Box, Icon, ListItemText, MenuItem, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import formatTextCamelCase from '../../../utils/formatTextCamelCase';
import CustomTable from '../../../components/Table/Table';
import Loading from '../../../components/Loader/Loading';

export default function TransacoesPix() {
  const dispatch = useDispatch();

  const [transactions, setTransactions] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subPaymentMethods, setSubPaymentMethods] = useState([{ id: 0, label: "Todos" }]);
  const [transaction, setTransaction] = useState(false);
  const [modalTranscation, setModalTransaction] = useState(false);
  const [tiposCanal, setTiposCanal] = useState([{ ID: 'Todos', DESCRICAO: 'Todos' }]);
  const [tiposTransacao, setTiposTransacao] = useState([{ ID: 'Todos', DESCRICAO: "Todos"}]);

  const { parceiro } = useSelector((state) => state.transactions);

  useEffect(() => {
    dispatch(getTransactionsType());
  }, []);

  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      page: 1,
      limit: 10,
      pesquisa_pagador: '',
      pesquisa_recebedor: '',
      parceiro: '',
      conta: '',
      data_inicial: moment().subtract(1, 'M').format('YYYY-MM-DD'),
      data_final: moment().format('YYYY-MM-DD'),
      submodo_pagamento: '',
      tipo_canal: '',
      tipo_transacao: '',
    }
  })

  function getSubPaymentMethods() {
    api.get(`/transacao/submodo-pagamento?ID_MODOPAGAMENTO=20`).then(({ data }) => {
      if (data.retorno === 'sucesso') {
        setSubPaymentMethods([{ id: 0, label: "Todos" }, ...data.data.map(({ ID, DESCRICAO }) => ({ id: ID, label: formatTextCamelCase(DESCRICAO) }))]);
      }
    });
  }

  useEffect(() => {
    getTransactions();
    getSubPaymentMethods();
    api
      .get('/admin/tipos-canal')
      .then(({ data: { data } }) => setTiposCanal([{ ID: 'Todos', DESCRICAO: 'Todos' }, ...data]));
    api
      .get('/tipo-transacao')
      .then(({data: {tipos_transacao}}) => setTiposTransacao([{ ID: 'Todos', DESCRICAO: "Todos"}, ...tipos_transacao]))
  }, []);


  function getTransactions(filter = getValues()) {
    setLoading(true);
    const params = new URLSearchParams(filter);
    api
      .get(`/transacoes-pix?${params.toString().replaceAll('Todos', '')}`)
      .then(({ data }) => {
        if (data.retorno === 'sucesso') {
          setTransactions(data.data);
          setPagination(data.pagination);
        }
      })
      .finally(() => setLoading(false));
  }

  function handleClick(transaction) {
    setTransaction(transaction);
    setModalTransaction(true);
  };

  return (
    <Box>
      <Box>
        <form
          onSubmit={handleSubmit(getTransactions)}
          style={{
            display: 'flex',
            flexFlow: 'column',
            width: '100%',
            justifyContent: 'flex-start',
            gap: '1rem',
            margin: '2rem 0',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            sx={{ gap: '1rem' }}
          >
            <TextField
              {...register("pesquisa_pagador")}
              autoFocus
              label="Pesquisa Pagador"
              placeholder="Nome, Conta ou CPF/CNPJ"
              variant="outlined"
              size='small'
              sx={{ width: '20rem' }}
            />
            <TextField
              {...register("pesquisa_recebedor")}
              label="Pesquisa Recebedor"
              placeholder="Nome, Conta ou CPF/CNPJ"
              variant="outlined"
              size='small'
              sx={{ width: '20rem' }}
            />

            <Autocomplete
              defaultValue="Todos"
              onChange={(event, value) => setValue('parceiro', value ? value.id : '')}
              options={parceiro ? [{ id: 0, label: "Todos" }, ...parceiro.map(({ ID, DESCRICAO }) => ({ id: ID, label: DESCRICAO }))] : [{ id: 0, label: "Todos" }]}
              renderOption={(params, item) => (
                <li {...params} key={item.id}>
                  <ListItemText>{item.label}</ListItemText>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Parceiro" />}
              noOptionsText="Parceiro não encontrado"
              size='small'
              sx={{ width: '15rem' }}
              variant="outlined"
            />

            <Autocomplete
              defaultValue="Todos"
              onChange={(event, value) => setValue('submodo_pagamento', value ? value.id : '')}
              options={subPaymentMethods}
              renderOption={(params, item) => (
                <li {...params} key={item.id}>
                  <ListItemText>{item.label}</ListItemText>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Sub Modo Pagamento" />}
              noOptionsText="Sub Modo pagamento não encontrado"
              size='small'
              sx={{ width: '15rem' }}
              variant="outlined"
            />

            <TextField
              select
              label="Canal"
              defaultValue='Todos'
              variant="outlined"
              size='small'
              sx={{ width: '15rem' }}
              {...register('tipo_canal')}
            >
              {tiposCanal.map(({ ID, DESCRICAO }, index) => (
                <MenuItem key={index} value={ID}>
                  {formatTextCamelCase(DESCRICAO)}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Tipo Transação"
              defaultValue='Todos'
              variant="outlined"
              size='small'
              sx={{ width: '15rem' }}
              {...register('tipo_transacao')}
            >
              {tiposTransacao.map(({ ID, DESCRICAO }, index) => (
                <MenuItem key={index} value={ID}>
                  {formatTextCamelCase(DESCRICAO)}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Data Inicial"
              {...register("data_inicial")}
              type='date'
              variant="outlined"
              size='small'
              sx={{ width: '15rem' }}
            />

            <TextField
              label="Data Final"
              {...register("data_final")}
              type='date'
              variant="outlined"
              size='small'
              sx={{ width: '15rem' }}
            />

            <GenericButton height="40px" width="140px" type="submit">
              <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search" />
              Buscar
            </GenericButton>
          </Box>
          <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} sx={{ gap: '1rem' }}>
            <TextField
              {...register("limit")}
              select
              label="Limite"
              variant="outlined"
              size='small'
              defaultValue="10"
              sx={{ width: '10rem' }}
            >
              {['5', '10', '25', '50', '100', '1000'].map((label, index) => (
                <MenuItem key={index} value={label}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <span style={{ fontSize: 'small' }}>Resultados por página</span>
          </Box>
        </form>
      </Box>

      <div style={{ marginTop: '1rem' }}>
        {
          loading ? <Loading color="blue" /> : (
            <CustomTable
                headers={[
                  {
                    info: 'DATA',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'E2E',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'CPF/CNPJ',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'CONTA PAGADOR',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: "NOME",
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'CONTA RECEBEDOR',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: "NOME DA TRANSFERÊNCIA",
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'VALOR',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'PARCEIRO',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'CANAL',
                    style: { textAlign: 'center' },
                  }
                ]}
                data={
                  transactions &&
                  transactions.map((row) => {
                    return {
                      data: [
                        {
                          info: formatDateBR(row.DATA),
                          style: { textAlign: 'center' },
                        },
                        {
                          info: row.ID_HASH,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: row.CPFCNPJ && isCpf(row.CPFCNPJ) ? formatCPF(row.CPFCNPJ) : formatCNPJ(row.CPFCNPJ),
                          style: { textAlign: 'center' },
                        },
                        {
                          info: row.CONTA,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: row.IDTIPOTRANSACAO == 1 ? row.NOME_PAGADOR : row.NOME_RECEBEDOR,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: row.CONTA_DA_TRANSFERENCIA ? row.CONTA_DA_TRANSFERENCIA : '----',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: row.IDTIPOTRANSACAO == 1 ? row.NOME_RECEBEDOR : row.NOME_PAGADOR,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: valueEmpty(row.VALOR ? <StatusContainerValue code={row.TIPO_TRANSACAO} description={row.VALOR} /> : null),
                          style: { textAlign: 'center' },
                        },
                        {
                          info: row.PARCEIRO ? (
                            <StatusContainer code={row.PARCEIRO.toUpperCase()} description={row.PARCEIRO}></StatusContainer>
                          ) : '-----',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: row.CANAL,
                          style: { textAlign: 'center' },
                        }
                      ],
                      onClick: () => handleClick(row)
                    };
                  })
                }
                pagination={pagination}
                previousPage={{ onClick: () => getTransactions({ ...getValues(), page: pagination.prevPage }) }}
                nowPage={{ page: pagination.page, onClick: () => getTransactions({ ...getValues(), page: pagination.page }) }}
                nextPage={{ onClick: () => getTransactions({ ...getValues(), page: pagination.nextPage }) }}
              />
          )
        }
      </div>

      <ModalTransferenciasPix
        toggle={() => {
          setTransaction(false);
          setModalTransaction(false);
        }}
        open={modalTranscation}
        transaction={transaction}
      />
    </Box>
  );
}
