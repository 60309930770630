import React, { useState } from 'react';
import { solicitarCartaoSwap } from '../../../store/user/actions';
import { connect, useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import AnimatedLoading from '../../../components/Loader/AnimatedLoading';

const ModalQRCODE = (props) => {
  const { isOpen, modalTransition, backdropTransition, toggle } = props;

  const [cardId, setCardId] = useState('');
  const [mensagemRequest, setMensagemRequest] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [visible, setVisible] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [vinculoCartao, setVinculoCartao] = useState(0);

  const onDismiss = () => setVisible(!visible);

  const user = props.user.user || props.userData;

  const params = {
    UUID: user.UUID,
    CARD_ID: cardId,
    VIRTUAL: 'false',
    TIPO_CONTA: vinculoCartao,
    ID_CONTA_BACEN: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].ID : null,
  };

  const openModalMessage = (res) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);
    setLoaded(false);
    setMensagemRequest(mensagem);
    setModalMensagem(true);
  };

  const click = () => {
    if (cardId == '') {
      setVisible(!visible);
      setMensagemAlerta('Preencha os campos');
      setLoaded(true);
    } else if (user.STATUS == 7) {
      props.solicitarCartaoSwap(params, (res) => {
        openModalMessage(res);
      });
    }
  };

  const alert = () => {
    return (
      <Alert color="danger" isOpen={visible} toggle={() => onDismiss()}>
        {mensagemAlerta}
      </Alert>
    );
  };

  return (
    <Modal isOpen={isOpen} modalTransition={modalTransition} backdropTransition={backdropTransition} toggle={toggle}>
      <ModalHeader
        toggle={() => {
          toggle();
          setVinculoCartao(false);
        }}
      >
        Cadastrar Cartão Não Nominal
      </ModalHeader>
      <FormGroup>
        <ModalBody>
          <span>Selecione o vínculo do cartão</span>

          <Input
            onChange={(e) => setVinculoCartao(e.target.value)}
            style={{ marginBottom: '1rem' }}
            type="select"
            size="sm"
          >
            <option value={0}>Selecione</option>
            <option value={1}>Conta bancária</option>
            <option value={2}>Conta cartão</option>
          </Input>
          <span>Digite o QR CODE do Cartão</span>
          <Input placeholder="QR CODE" size="sm" onChange={(e) => setCardId(e.target.value)}></Input>
          {alert()}
        </ModalBody>
      </FormGroup>
      <AnimatedLoading isActive={loaded} />

      <ModalFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            style={{ marginTop: '8px' }}
            color="secondary"
            size="sm"
            onClick={() => {
              toggle();
              setVinculoCartao(false);
            }}
            block
          >
            Cancelar
          </Button>
          <Button
            color="success"
            size="sm"
            onClick={() => {
              click();
              setLoaded(true);
            }}
            disabled={vinculoCartao == 0}
            block
          >
            Confirmar
          </Button>
        </div>
      </ModalFooter>

      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagemRequest}
        sucesso={sucesso}
        toggle={() => setModalMensagem(false)}
      />
    </Modal>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  solicitarCartaoSwap,
})(ModalQRCODE);
