import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import { useEffect } from 'react';
import formatCPF from '../../utils/formatCPF';
import formatCNPJ from '../../utils/formatCNPJ';
import { getAllUsers } from '../../store/dashboard/actions';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import CustomContainer from '../../components/Custom/CustomContainer';
import * as Styled from './GerenciarClientes.styles';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalSenha from '../../components/Modais/Confirmar/ModalSenha';
import InputStatus from '../../components/Filter/components/InputStatus';
import InputSearch from '../../components/Filter/components/InputSearch';
import InputDate from '../../components/Filter/components/InputDate';
import { InputsContainer } from '../../components/Filter/styles/Filter.style';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults.js';
import Filter from '../../components/Filter/Filter';
import moment from 'moment';
import ModalCliente from '../../components/Modais/Cliente/ModalCliente';
import {
  listaUserDetalhes,
  revertUser,
  criarContaDigital,
  pegarRiscoUsuario,
  statusUsuario,
} from '../../store/user/actions';
import { listarCoban } from '../../store/coban/actions';
import Loading from '../../components/Loader/Loading';
import ModalMensagem from '../Transaction/ModalMensagem';
import Tooltip from '@material-ui/core/Tooltip';
import GenericButton from '../../components/Buttons/Button';
import Icon from '../../components/Icons/IconsButton';
import { getUsersExcel } from '../../store/user/actions';
import { getTransactionsType } from '../../store/transactions/actions';
import GenericModal from '../../components/Modais/GenericModal';
import isCpf from '../../utils/isCpf';
import formatTextCamelCase from '../../utils/formatTextCamelCase.js';

function GerenciarClientes(props) {
  const dispatch = useDispatch();

  const [modalDenyUser, setModalDenyUser] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalPendingUser, setModalPendingUser] = useState(false);
  const [modalFinishUser, setModalFinishUser] = useState(false);
  const [modalPasswordDenyUser, setModalPasswordDenyUser] = useState(false);
  const [modalPasswordContaDigital, setModalPasswordContaDigital] = useState(false);
  const [modalContaDigital, setModalContaDigital] = useState(false);
  const [message, setMessage] = useState('');
  const [modalMessage, setModalMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState('');
  const [parceiros, setParceiros] = useState('');
  const [idParceiros, setIdParceiros] = useState('');
  const [filter, setFilter] = useState({
    limit: 10,
    search: '',
    page: 1,
    perfil: '',
    status: '',
    tipo_usuario: '',
    dataInicial: '',
    dataFinal: moment().format('YYYY-MM-DD'),
  });

  const [loading, setLoading] = useState(false);

  const [modalBaas, setModalBaas] = useState({
    open: false,
    header: '',
    body: '',
    footer: '',
  });

  const { list } = useSelector((state) => state.coban);

  useEffect(() => {
    dispatch(getAllUsers(filter));
  }, [filter.page]);

  const { dashboard: { userPagination, listUsers, loading_dashboard, pendingUsers }, transactions: { parceiro }, user: { status_usuario, user, loading_user_list } } = useSelector((state) => state);

  const selectedPendingUsers = [!pendingUsers, filter.status == '1', !pendingUsers];

  //use effect feito para não ter os dois modais abertos do usuário pendente ao mesmo tempo
  useEffect(() => {
    if (modalContaDigital) {
      setModalPendingUser(false);
    }
  }, [modalContaDigital]);

  useEffect(() => {
    if (pendingUsers) {
      setFilter((prev) => ({ ...prev, status: '1' }));
    }
  }, [pendingUsers]);

  useEffect(() => {
    setLoading(true);
    dispatch(pegarRiscoUsuario());
    dispatch(getTransactionsType());
    dispatch(statusUsuario());
    dispatch(listarCoban());
    localStorage.getItem('filtro_gerenciar_clientes') && setFilter(JSON.parse(atob(localStorage.getItem('filtro_gerenciar_clientes'))));
  }, []);

  useEffect(() => {
    if (parceiro) {
      setParceiros(parceiro.map((parceiro) => parceiro.DESCRICAO));
      setIdParceiros(parceiro.map((parceiro) => parceiro.ID));
      localStorage.getItem('filtro_gerenciar_clientes') && setFilter(JSON.parse(atob(localStorage.getItem('filtro_gerenciar_clientes'))));
      setLoading(false);
  }
  }, [parceiro]);


  const toggleModalDenyUser = () => setModalDenyUser(!modalDenyUser);
  const toggleModalLoading = () => setModalLoading(!modalLoading);
  const toggleModalContaDigital = () => {
    setModalContaDigital(!modalContaDigital);
  };
  const toggleModalFinishUser = () => setModalFinishUser(!modalFinishUser);

  const closeModalDenyUser = () => setModalDenyUser(false);
  const closeModalLoading = () => setModalLoading(false);

  const closeModalFinishUser = () => setModalFinishUser(false);
  const openModalFinishUser = () => setModalFinishUser(true);
  const closeModalContaDigital = () => setModalContaDigital(false);

  const openModalLoading = () => setModalLoading(true);

  const openModalPasswordContaDigital = () => setModalPasswordContaDigital(true);

  function openModalMessage(res) {
    const { mensagem, erro, retorno } = res;
    setMessage(mensagem);
    setModalMessage(true);
    closeModalLoading();
    if (retorno && retorno == 'sucesso') setSuccess(true);
    if (!erro) setSuccess(true);
  }

  const revertUserToPendingStatus = () => {
    openModalLoading();
    dispatch(
      revertUser({ CPFUSUARIO: userData.CPFCNPJ }, (res) => {
        toggleModalDenyUser();
        openModalMessage(res);
      }),
    );
  };

  const aprovarContaDigital = () => {
    openModalLoading();
    dispatch(
      criarContaDigital({ CPFCNPJ_USUARIO: userData.CPFCNPJ }, (res) => {
        openModalMessage(res);
      }),
    );
  };

  function callbackMsBass(callback) {
    setModalBaas({ open: false, header: '', body: '', footer: '' });
    setModalBaas({
      open: true,
      header: <h1>{callback.retorno}</h1>,
      body: <span>{callback.mensagem}</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color={callback.retorno == 'erro' ? 'danger' : 'success'}
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }

  const nextModal = (user) => {
    setUserData(user);
    if (status_usuario.some((({ID, DESCRICAO}) => (DESCRICAO == 'AGUARDANDO' || DESCRICAO == 'NEGADO') && ID == +user.CODSTATUS))) {
      dispatch(listaUserDetalhes(user.UUID));
      setModalPendingUser(true);
    }
  };

  const tablePagination = async (type = 'atual', pageClick) => {
    let page = 1;
    switch (type) {
      case 'prev':
        page = userPagination.prevPage;
        break;
      case 'next':
        page = userPagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }

    await setFilter({ ...filter, page });
  };


  return (
    <div>
      <CustomContainer>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <InputsContainer>
              <InputSearch
                width="20vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
                disabled={loading}
              />

              <InputStatus
                pendingUsers={selectedPendingUsers}
                inputName={'Perfil'}
                setValue={(value) => setFilter({ ...filter, perfil: value })}
                options={['Todos', ...list.map(({NOME}) => NOME)]}
                codeValue={['', ...list.map(({ID}) => +ID)]}
                value={filter.perfil}
                disabled={loading}
                width="15rem"
              />
              <InputStatus
                pendingUsers={selectedPendingUsers}
                setValue={(value) => setFilter({ ...filter, status: value })}
                options={['Todos', ...status_usuario.map(({DESCRICAO}) => formatTextCamelCase(DESCRICAO))]}
                codeValue={['', ...status_usuario.map(({ID}) => +ID)]}
                value={filter.status}
                disabled={loading}
                width="15rem"
              />
              <InputStatus
                pendingUsers={selectedPendingUsers}
                inputName={'Parceiro'}
                setValue={(value) => setFilter({ ...filter, tipo_usuario: value })}
                options={['Todos', ...parceiros]}
                codeValue={['', ...idParceiros]}
                value={filter.tipo_usuario}
                disabled={loading}
                width="15rem"
              />

              <InputDate
                label="Data Inicial"
                name="dataInicial"
                value={filter.dataInicial}
                setValue={(value) => setFilter({ ...filter, dataInicial: value })}
                disabled={loading}
              />

              <InputDate
                label="Data Final"
                name="dataFinal"
                value={filter.dataFinal}
                setValue={(value) => setFilter({ ...filter, dataFinal: value })}
                disabled={loading}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '0.6rem',
                }}
              >
                <GenericButton
                  type="submit"
                  onClick={() => {
                    dispatch(getAllUsers(filter));
                    filter.page = '1';
                    localStorage.setItem('filtro_gerenciar_clientes', btoa(JSON.stringify(filter)));
                  }}
                  marginTop={'25px !important'}
                  width={'140px'}
                  heigth={'35px'}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
                <GenericButton
                  type="submit"
                  onClick={() => {
                    setFilter({
                      limit: 10,
                      search: '',
                      page: 1,
                      perfil: '',
                      status: '',
                      tipo_usuario: '',
                      dataInicial: '',
                      dataFinal: moment().format('YYYY-MM-DD'),
                    });
                    localStorage.setItem('filtro_gerenciar_clientes', '');
                  }}
                  marginTop={'25px !important'}
                  width={'140px'}
                  heigth={'35px'}
                  backgroundColor={'#5B6A82'}
                  borderColor={'#5B6A82'}
                >
                  <Icon className="fas fa-trash"></Icon>Limpar Filtros
                </GenericButton>
                <GenericButton
                  onClick={() => {
                    dispatch(getUsersExcel(filter));
                  }}
                  marginTop={'25px !important'}
                  backgroundColor={'#ffffff'}
                  borderColor={'#2405F2'}
                  width={'140px'}
                  heigth={'35px'}
                  marginLeft={'10px'}
                  color={'#2405F2'}
                >
                  <Icon className="fas fa-file-export"></Icon>Exportar
                </GenericButton>
              </div>
            </InputsContainer>
          </Form>
          <Styled.infoInput value={'Total de Usuários ' + userPagination.totalResult}></Styled.infoInput>
          <div>
            <InputSelectAmountResults
              options={['10', '25', '50', '100', '1000']}
              value={filter.limit}
              setValue={(value) => setFilter({ ...filter, limit: value })}
            />
          </div>
        </Filter>
        {!userPagination ? (
          <h4 style={{ textAlign: 'center' }}>Carregando Transações</h4>
        ) : (
          <div>
            {loading_dashboard ? (
              <Loading color="#11cdef" />
            ) : (
              <Table
                headers={[
                  {
                    info: 'Data de Registro',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'CPF/CNPJ',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Nome',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Conta',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Perfil',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Parceiro',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Status',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Saldo',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Ações',
                    style: { textAlign: 'center' },
                  },
                ]}
                data={listUsers.map((user) => {
                  return {
                    data: [
                      {
                        info: user.DATA,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.CPFCNPJ
                          ? isCpf(user.CPFCNPJ)
                            ? formatCPF(user.CPFCNPJ)
                            : formatCNPJ(user.CPFCNPJ)
                          : null,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: user.NOME,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: user.CONTA ? user.CONTA : '-----',
                        style: { textAlign: 'left' },
                      },

                      {
                        info: user.NOMECOBAN ? user.NOMECOBAN : '-----',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: user.STATUS_CONTA_DIGITAL ? (
                          <StatusContainer
                            code={user.TIPO_USUARIO.toUpperCase()}
                            description={user.TIPO_USUARIO}
                          ></StatusContainer>
                        ) : (
                          '-----'
                        ),
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.STATUS ? (
                          <StatusContainer code={user.CODSTATUS} description={user.STATUS}></StatusContainer>
                        ) : (
                          '-----'
                        ),
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.SALDO ?  `${(+user.SALDO).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : '-----',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: (
                          <Styled.IconContainer>
                            <a
                              href={`${props.match.path}/view?user=${user.UUID}`}
                            >
                              { !status_usuario.some((({ID, DESCRICAO}) => (DESCRICAO == 'AGUARDANDO' || DESCRICAO == 'NEGADO') && ID == +user.CODSTATUS)) && (
                                <Tooltip title="Ver Perfil" arrow>
                                  <div>{<Styled.UserEditIcon />}</div>
                                </Tooltip>
                              )}
                            </a>
                          </Styled.IconContainer>
                        ),
                        style: { textAlign: 'center' },
                      },
                    ],
                    onClick: () => nextModal(user),
                  };
                })}
                pagination={userPagination}
                previousPage={{ onClick: () => tablePagination('prev') }}
                nowPage={{ page: filter.page, onClick: userPagination }}
                nextPage={{ onClick: () => tablePagination('next') }}
              />
            )}
          </div>
        )}
      </CustomContainer>

      <Modal isOpen={modalLoading} toggle={toggleModalLoading}>
        <ModalBody>
          <Styled.LoadingModalContainer>
            <Styled.Spinner size="100px" />
            <Styled.LoadingModalTitle>Carregando</Styled.LoadingModalTitle>
          </Styled.LoadingModalContainer>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalFinishUser} toggle={toggleModalFinishUser}>
        <ModalHeader>Detalhes do Encerramento</ModalHeader>
        <ModalBody>
          {user != null && !loading_user_list && user.CONTA_BACEN.length > 0 ? (
            <div>
              <div>
                <Styled.ModalSubtitle>Data de Encerramento</Styled.ModalSubtitle>
                <p>{moment(user.CONTA_BACEN[0].DT_ENCERRAMENTO).format('DD/MM/YYYY')}</p>
              </div>
              <div>
                <Styled.ModalSubtitle>Justificativa</Styled.ModalSubtitle>
                <p>{user.CONTA_BACEN[0].MOTIVO_ENCERRAMENTO}</p>
              </div>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <>
            <Button size="sm" block onClick={() => closeModalFinishUser()}>
              Voltar
            </Button>
          </>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalContaDigital} toggle={toggleModalContaDigital}>
        <ModalHeader>Solicitação de conta digital</ModalHeader>
        <ModalBody>
          <Styled.ModalSubtitle>Deseja solicitar conta digital?</Styled.ModalSubtitle>
        </ModalBody>
        <ModalFooter>
          <>
            <Button size="sm" block onClick={() => toggleModalContaDigital()}>
              Cancelar
            </Button>
            <Styled.RevertButton
              size="sm"
              block
              onClick={() => {
                openModalPasswordContaDigital();
                closeModalContaDigital();
              }}
            >
              Sim
            </Styled.RevertButton>
          </>
        </ModalFooter>
      </Modal>

      <ModalSenha
        modal={modalPasswordDenyUser}
        close={() => setModalPasswordDenyUser(false)}
        dados={userData.CPFCNPJ}
        nextFunction={revertUserToPendingStatus}
      />

      <ModalSenha
        modal={modalPasswordContaDigital}
        close={() => setModalPasswordContaDigital(false)}
        dados={userData.CPFCNPJ}
        nextFunction={aprovarContaDigital}
      />

      <ModalCliente modal={modalPendingUser} toggle={() => setModalPendingUser(false)} type={'1'} />

      <ModalMensagem
        modal={modalMessage}
        toggle={() => {
          setModalMessage(false);
        }}
        mensagem={message}
        sucesso={success}
      />

      <GenericModal
        open={modalBaas.open}
        close={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
        header={modalBaas.header}
        body={modalBaas.body}
        footer={modalBaas.footer}
      />
    </div>
  );
}

export default GerenciarClientes;
